import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Container, Form } from "react-bootstrap";
import { ChakraProvider } from "@chakra-ui/react";
import logo from "../img/logo.png";
import { Link } from "react-router-dom";

function navbar(props) {
  return (
    <div>
      <ChakraProvider>
        <Navbar
          variant="dark"
          className="align-center frostedglass"
          fixed="top"
        >
          <Container fluid>
            <Link to={`/`}>
              <Navbar.Brand>
                <img
                  alt="minerpunk"
                  src={logo}
                  width="130"
                  className="d-inline-block align-center "
                />
              </Navbar.Brand>
            </Link>
            <Form
              className="d-flex"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {props.lang}
              {props.name}
            </Form>
          </Container>
        </Navbar>
      </ChakraProvider>
    </div>
  );
}

export default navbar;
