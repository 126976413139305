import { useBoolean } from "@chakra-ui/react";

import DC from "../img/icon/DC.png";
import IG from "../img/icon/IG.png";
import TG from "../img/icon/TG.png";
import TW from "../img/icon/TW.png";
import uniswap from "../img/icon/uniswap.png";
import X2Y2 from "../img/icon/X2Y2.png";
import X2Y2_dark from "../img/icon/X2Y2_dark.png";
import looksrare from "../img/icon/looksrare.png";
import looksrare_dark from "../img/icon/looksrare_dark.png";
import etherscan from "../img/icon/etherscan.png";
import github from "../img/icon/github.png";

const icon = {
  DC: { icon: DC, url: "https://discord.gg/ePUJxpfKYV" },
  IG: { icon: IG, url: "https://www.instagram.com/minerpunk/" },
  TG: { icon: TG, url: "https://t.me/minerpunk/" },
  TW: { icon: TW, url: "https://twitter.com/minerpunk_eth/" },
  github: { icon: github, url: "https://github.com/to101380/MinerPunk_V1" },

  X2Y2: {
    icon: X2Y2,
    url: "https://x2y2.io/collection/minerpunk-eth/items",
  },

  looksrares: {
    icon: looksrare,
    url: "https://looksrare.org/collections/0x90544049d50c012caF6F5F1C10344b7A9c05A064",
  },

  X2Y2_ERC1155: {
    icon: X2Y2,
    url: "https://x2y2.io/collection/minerpunkbadges/items",
  },

  lookrares_ERC1155: {
    icon: looksrare,
    url: "https://looksrare.org/collections/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D",
  },

  X2Y2_Dungeon: {
    icon: X2Y2,
    url: "https://x2y2.io/collection/minerpunkdungeon/items",
  },

  lookrares_Dungeon: {
    icon: looksrare,
    url: "https://looksrare.org/collections/0x32dC394a70090e2e617ea6F9aa0fe4daD4691F0c?queryID=ac5e60c8388ed5c7befaa30fd993a915",
  },
  uniswap: {
    icon: uniswap,
    url: "https://app.uniswap.org/#/swap?outputCurrency=0xC74Ab3FF349cE64a349854BcD5dc2D542371350F",
  },

  etherscan: {
    icon: etherscan,
    url: "https://etherscan.io/address/0xC74Ab3FF349cE64a349854BcD5dc2D542371350F",
  },
};

export function Socialmedia(i) {
  const [flag, setFlag] = useBoolean();

  return (
    <div
      onMouseEnter={setFlag.on}
      onMouseLeave={setFlag.off}
      className="row_center"
    >
      {flag ? (
        <a href={icon[i].url} target="_blank" rel="noopener noreferrer">
          <img
            alt="dcoffer"
            src={icon[i].icon}
            width="22px"
            style={{
              opacity: "0.3",
              transition: " 200ms",
              margin: "8px",
            }}
          />
        </a>
      ) : (
        <a href={icon[i].url} target="_blank" rel="noopener noreferrer">
          <img
            alt="dcoffer"
            src={icon[i].icon}
            width="22px"
            style={{
              margin: "8px",
            }}
          />
        </a>
      )}
    </div>
  );
}

export function Media(icon, url) {
  const [flag, setFlag] = useBoolean();
  return (
    <div
      onMouseEnter={setFlag.on}
      onMouseLeave={setFlag.off}
      className="row_center"
    >
      {flag ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img
            alt="dcoffer"
            src={icon}
            width="22px"
            style={{
              opacity: "0.3",
              transition: " 200ms",
              margin: "8px",
            }}
          />
        </a>
      ) : (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img
            alt="dcoffer"
            src={icon}
            width="22px"
            style={{
              margin: "8px",
            }}
          />
        </a>
      )}
    </div>
  );
}
