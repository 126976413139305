import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Miner from "./routes/Miner";
import Airdrop from "./routes/Airdrop";
import FreeMint from "./routes/FreeMint";
import Cashbox from "./routes/Cashbox";
import MinerpunkDungeon from "./routes/MinerpunkDungeon";
import Nopage from "./routes/oops404";
import BrandStory from "./routes/BrandStory";
import MintModel from "./Components/Mint";
import IncomeBoardModel from "./Components/IncomeBoard";
import TokenInfoModel from "./Components/TokenInfo";
import BadgebarModel from "./Components/Badgebar";
import MDmintCardModel from "./Components/MDmint";
import MDMineModel from "./Components/MDmine";
import MDIncomeCardModel from "./Components/MDIncome";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { mainnet } from "wagmi/chains";

const chains = [mainnet];
const projectId = "4c021657b0a10430062a077d8e062899";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Nopage />,
    children: [
      {
        path: "/:paramName",
        element: <App />,
        errorElement: <Nopage />,
      },
    ],
  },
  {
    path: "Miner",
    element: <Miner />,
    children: [
      {
        path: "Mint/:paramName",
        element: <MintModel />,
        errorElement: <Nopage />,
      },
      {
        path: "Income/:paramName",
        element: <IncomeBoardModel />,
        errorElement: <Nopage />,
      },
      {
        path: "token/:paramName",
        element: <TokenInfoModel />,
        errorElement: <Nopage />,
      },
      {
        path: "badges/:paramName",
        element: <BadgebarModel />,
        errorElement: <Nopage />,
      },
    ],
  },

  {
    path: "/Airdrop",
    element: <Airdrop />,
    errorElement: <Nopage />,
    children: [
      {
        path: ":paramName",
        element: <Airdrop />,
        errorElement: <Nopage />,
      },
    ],
  },
  {
    path: "/Freemint",
    element: <FreeMint />,
    errorElement: <Nopage />,
    children: [
      {
        path: ":paramName",
        element: <FreeMint />,
        errorElement: <Nopage />,
      },
    ],
  },
  {
    path: "/Cashbox",
    element: <Cashbox />,
    errorElement: <Nopage />,
    children: [
      {
        path: ":paramName",
        element: <Cashbox />,
        errorElement: <Nopage />,
      },
    ],
  },
  {
    path: "/BrandStory",
    element: <BrandStory />,
    errorElement: <Nopage />,
    children: [
      {
        path: ":paramName",
        element: <BrandStory />,
        errorElement: <Nopage />,
      },
    ],
  },
  {
    path: "/MinerpunkDungeon",
    element: <MinerpunkDungeon />,
    errorElement: <Nopage />,
    children: [
      {
        path: "MDmintCard/:paramName",
        element: <MDmintCardModel />,
        errorElement: <Nopage />,
      },
      {
        path: "MDmine/:paramName",
        element: <MDMineModel />,
        errorElement: <Nopage />,
      },
      {
        path: "MDIncome/:paramName",
        element: <MDIncomeCardModel />,
        errorElement: <Nopage />,
      },
    ],
  },
  {
    path: "*",
    element: <Nopage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </WagmiConfig>
    <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
  </React.StrictMode>
);
