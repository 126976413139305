import { useState, useEffect } from "react";
import { getSmartContract } from "../SmartContract";
import { useContractRead, usePrepareContractWrite } from "wagmi";

const Smartcontract = getSmartContract();

export function GetDCFpriceETH() {
  const { config, error } = usePrepareContractWrite({
    address: Smartcontract.uniswapPool.address,
    abi: Smartcontract.uniswapPool.abi,
    functionName: "quoteExactInputSingle",
    args: [
      "0xC74Ab3FF349cE64a349854BcD5dc2D542371350F",
      "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      "3000",
      "1000000000000000000",
      0,
    ],
  });

  let price = Number(config.result);
  price = (price / 1e18).toFixed(8);

  if (isNaN(price)) return 0;
  return price;
}

export function GetDCFpriceUSD() {
  const [priceInUSD, setpriceInUSD] = useState(0);
  const { data, isError, isLoading } = useContractRead({
    address: Smartcontract.chainlink.address,
    abi: Smartcontract.chainlink.abi,
    functionName: "latestAnswer",
    watch: true,
  });

  const ethPrice = GetDCFpriceETH();

  useEffect(() => {
    if (!isError || !isLoading || !isNaN(ethPrice)) {
      setpriceInUSD(ethPrice);
    }
  }, [ethPrice]);

  if (isNaN(priceInUSD) == true) return 0;

  let DCFUSD = ((Number(data) / 1e8) * priceInUSD).toFixed(5);
  return Number(DCFUSD);
}
