import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate, useParams } from "react-router-dom";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-animated-3d-card";
import { Container, Row, Col } from "react-bootstrap";
import { getSmartContract } from "../SmartContract";
import { getlangselect } from "../Components/lang/langselect";
import { Socialmedia } from "../Components/Socialmedia";
import { _Percent } from "../Components/Tool";
import { MDBlockStatus } from "../Components/blockstatus";
import { truncateAddress } from "../utils";
import {
  MinePower,
  DocffBalnance,
  DcoffamountCondition,
  PowerCondition,
  MinerpunkDungeonPrice,
  MDbalanceOf,
  MDmaxSupply,
  MDdugUp,
  MyMDdugUp,
  ViewMDReward,
  MDSlideFadeEx,
  MDmintBtn,
  MDDayincome,
  IsComplete,
} from "../Components/web3";

import Navbar from "../Components/Navbar";
import mainLogo from "../img/mainLogo.png";

import {
  ChakraProvider,
  useToast,
  Select,
  Button,
  Text,
  Heading,
  Center,
  UnorderedList,
  ListItem,
  Divider,
} from "@chakra-ui/react";

import {
  CheckCircleIcon,
  NotAllowedIcon,
  ArrowForwardIcon,
  WarningIcon,
} from "@chakra-ui/icons";

import { Web3Button } from "@web3modal/react";

import {
  useContractRead,
  useAccount,
  useBlockNumber,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

const Smartcontract = getSmartContract();

function MyLang() {
  const currentURL = window.location.href;
  const [lang, setLang] = useState("en");
  const navigate = useNavigate();
  let { paramName } = useParams();

  const url = currentURL;
  const parts = url.split("/"); // 將 URL 字串根據 '/' 符號分割成數組
  const parameter1 = parts[4]; // Miner

  const handleSelectChange = (event) => {
    const newParamName = event.target.value;
    setLang(newParamName);
    // 使用navigate进行导航，更新URL中的参数
    navigate(`/MinerpunkDungeon/${parameter1}/${newParamName}`);
  };

  const _langSelect = (
    <Container>
      <Select
        value={paramName}
        onChange={handleSelectChange}
        color="teal"
        size="xs"
      >
        <option value="en">English</option>
        <option value="zh-tw">繁體中文</option>
        <option value="zh-cn">简体中文</option>
        <option value="ja">日本語</option>
      </Select>
    </Container>
  );

  return [lang, _langSelect];
}

const Navigation = () => {
  const [activeLink, setActiveLink] = useState("");
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  const currentURL = window.location.href;
  const url = currentURL;
  const parts = url.split("/"); // 將 URL 字串根據 '/' 符號分割成數組
  const parameter1 = parts[4]; //

  useEffect(() => {
    // 如果URL的paramName是一個我們知道的連結名稱，則設定它作為activeLink
    if (["MDMintCard", "MDmine", "MDIncome"].includes(parameter1)) {
      setActiveLink(parameter1);
    }
  }, [parameter1]); // 依賴於paramName以便當它更改時重新執行此效果

  const handleLinkClick = (link) => {
    setActiveLink(link);
    // 在點擊連結時將選擇的連結狀態保存到 localStorage
    localStorage.setItem("activeLink", link);
  };

  return (
    <nav className="navigation">
      <ul>
        <li className={activeLink === "MDMintCard" ? "active" : ""}>
          <Link
            to={`/MinerpunkDungeon/MDMintCard/${paramName}`}
            onClick={() => handleLinkClick("MDMintCard")}
            style={{ fontSize: "20px" }}
          >
            {LangPackage.minerpunkDungeon.Casting}
          </Link>
        </li>
        <li className={activeLink === "MDmine" ? "active" : ""}>
          <Link
            to={`/MinerpunkDungeon/MDmine/${paramName}`}
            onClick={() => handleLinkClick("MDmine")}
            style={{ fontSize: "20px" }}
          >
            {LangPackage.minerpunkDungeon.Mining}
          </Link>
        </li>
        <li className={activeLink === "MDIncome" ? "active" : ""}>
          <Link
            to={`/MinerpunkDungeon/MDIncome/${paramName}`}
            onClick={() => handleLinkClick("MDIncome")}
            style={{ fontSize: "20px" }}
          >
            {LangPackage.minerpunkDungeon.Earnings}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

function Dungeon() {
  const [lang, _langSelect] = MyLang();
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  let daily = (MDDayincome() / 61).toFixed(3);
  let Nav = Navigation();
  let isComplete = IsComplete();

  const { address } = useAccount();

  const MintBtn = (
    <div>
      <Link to={`/miner/Mint/${lang}`}>
        <Button
          colorScheme="teal"
          size="md"
          style={{
            width: "250px",
            marginTop: "10px",
            height: "50px",
          }}
        >
          Back to Minerpunk
        </Button>
      </Link>
    </div>
  );

  const RandomCard = () => {
    const [randomNumber, setRandomNumber] = useState(
      formatNumber(getRandom(0, 60))
    );

    // 這個 useEffect 只會在組件初次渲染時執行，所以它可以保證每次網頁重新整理時都能得到一個新的隨機數字
    useEffect(() => {
      setRandomNumber(formatNumber(getRandom(0, 60)));
    }, []);

    function getRandom(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function formatNumber(num) {
      return num < 10 ? "0" + num : "" + num;
    }

    const imageUrl = `https://gateway.pinata.cloud/ipfs/QmUUAj1Ztiwgg1uAJCBS3r3kacCv13iE37Z9GNKbBheYVR/${randomNumber}.png`;

    return (
      <Card
        style={{
          width: "320px",
          height: "420px",
          cursor: "pointer",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundPrepeat: "no-repeat",
          boxShadow: " 6px 10px 12px 2px rgba(0, 0, 0, 0.32)",
        }}
      >
        <div
          style={{
            height: "100%",
          }}
        >
          <img
            alt="ETH"
            src={imageUrl}
            style={{
              width: "325px",
              height: "425px",
            }}
          ></img>
        </div>

        <div>
          <label
            style={{
              color: "white",
              position: "absolute",
              bottom: "20px",
              left: "15px",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Minerpunk Dungeon
          </label>
        </div>
      </Card>
    );
  };

  const ConditionA = () => {
    const minePower = MinePower();
    const powerCondition = PowerCondition();

    return (
      <>
        <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
          {minePower >= powerCondition ? (
            <>
              <CheckCircleIcon w={4} h={4} color="green.500" />
            </>
          ) : (
            <>
              <CheckCircleIcon w={4} h={4} color="gray.500" />
            </>
          )}
          <div className="cos_word01" style={{ margin: "0px 15px" }}>
            {LangPackage.minerpunkDungeon.MinerpunkPower}
          </div>
        </div>
      </>
    );
  };

  const ConditionB = () => {
    const docffBalance = DocffBalnance();
    const dcoffAmountCondition = DcoffamountCondition();
    return (
      <>
        <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
          {docffBalance >= dcoffAmountCondition ? (
            <>
              <CheckCircleIcon w={4} h={4} color="green.500" />
            </>
          ) : (
            <>
              <CheckCircleIcon w={4} h={4} color="gray.500" />
            </>
          )}
          <div className="cos_word01" style={{ margin: "0px 15px" }}>
            {LangPackage.minerpunkDungeon.DCOFFBalance}
          </div>
        </div>
      </>
    );
  };

  const DisconnectInfo = () => {
    return (
      <>
        <div className="section_style_07" style={{ minHeight: "55vh" }}>
          <Container className="contentB">
            <div className="column_center " style={{ padding: "30px" }}>
              <img
                alt="Minerpunk Dungeon"
                src="https://i.imgur.com/gZAwJVL.png"
                width="130px"
                style={{ margin: "20px" }}
              ></img>
              <Text color="white" fontSize="2xl" as="b">
                Minerpunk Dungeon
              </Text>
              <div style={{ margin: "20px 0px" }}>
                <Text color="white" fontSize="sm" as="b">
                  {LangPackage.minerpunkDungeon.CurrentDailyEarnings} :
                </Text>
                <div style={{ margin: "20px 0px" }}>
                  <Text color="#00eacb" fontSize="2xl" as="b">
                    {daily}
                    <span style={{ color: "white", fontSize: "12px" }}>
                      {" "}
                      DCF / Minerpunk Dungeon
                    </span>
                  </Text>
                </div>
              </div>
              <Divider bg="white" style={{ width: "300px" }} />
              <UnorderedList fontSize="md" style={{ margin: "20px 0px" }}>
                <ListItem>
                  {LangPackage.minerpunkDungeon.OwnershipBenefits}
                </ListItem>
                <ListItem>
                  {LangPackage.minerpunkDungeon.DwarfMiningBenefit}
                </ListItem>
                <ListItem>{LangPackage.minerpunkDungeon.MaxIssue}</ListItem>
                <ListItem>
                  {LangPackage.minerpunkDungeon.AirdropPrivilege}
                </ListItem>
              </UnorderedList>

              {address ? <></> : <Web3Button />}
            </div>
          </Container>
        </div>
      </>
    );
  };

  const Qualified = () => {
    const disconnectInfo = DisconnectInfo();
    return (
      <>
        {disconnectInfo}
        <Container>
          <div
            className="column_center depictionCard"
            style={{ padding: "30px 0px" }}
          >
            <div>
              <ConditionA />
              <ConditionB />
            </div>
            <Text
              color="white"
              fontSize="lg"
              as="b"
              style={{ margin: "30px 0px" }}
            >
              {LangPackage.minerpunkDungeon.QualificationStatus}
            </Text>
            {MintBtn}
          </div>
        </Container>
      </>
    );
  };

  const DungeonInfo = () => {
    return (
      <div>
        <Container className="center">{Nav}</Container>
        <div
          id="detail"
          className="row_center section_style_07"
          style={{ color: "white" }}
        >
          <Outlet />
        </div>
      </div>
    );
  };

  return (
    <>
      <Navbar name={<Web3Button />} lang={_langSelect} />

      <section className="MDbody">
        <Container>
          <Row>
            <Col
              md={6}
              className="column_center"
              style={{ margin: "18px 0px" }}
            >
              {RandomCard()}

              <div className="depictionCard" style={{ padding: "15px" }}>
                <Text color="white" fontSize="md">
                  {LangPackage.minerpunkDungeon.SubscriptionConditions} :
                </Text>

                {ConditionA()}
                {ConditionB()}
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                {Socialmedia("X2Y2_Dungeon")}
                {Socialmedia("lookrares_Dungeon")}
              </div>
            </Col>

            <Col md={6} style={{ margin: "18px 0px" }}>
              {address
                ? isComplete
                  ? DungeonInfo()
                  : Qualified()
                : DisconnectInfo()}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

function MinerpunkDungeon() {
  return (
    <ChakraProvider>
      <Dungeon />
      <footer
        style={{ minHeight: "20vh", backgroundColor: "#1f202b" }}
      ></footer>
    </ChakraProvider>
  );
}

export default MinerpunkDungeon;
