import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import { getSmartContract } from "../SmartContract";
import { getlangselect } from "../Components/lang/langselect";
import { Socialmedia } from "../Components/Socialmedia";
import { _Percent } from "../Components/Tool";
import { GetDCFpriceUSD } from "../Components/pricing";

import Navbar from "../Components/Navbar";
import AirdropScroll from "../img/AirdropScroll.png";
import Dcofficon from "../img/myDcoff.png";

import {
  ChakraProvider,
  useToast,
  Select,
  Button,
  Text,
  Heading,
} from "@chakra-ui/react";

import { CheckCircleIcon, NotAllowedIcon } from "@chakra-ui/icons";

import { Web3Button } from "@web3modal/react";

import {
  useContractRead,
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

const Smartcontract = getSmartContract();

function MyLang() {
  const [lang, setLang] = useState("en");
  const navigate = useNavigate();
  let { paramName } = useParams();

  const handleSelectChange = (event) => {
    const newParamName = event.target.value;
    setLang(newParamName);
    // 使用navigate进行导航，更新URL中的参数
    navigate(`/airdrop/${newParamName}`);
  };

  const _langSelect = (
    <Container>
      <Select
        value={paramName}
        onChange={handleSelectChange}
        color="teal"
        size="xs"
      >
        <option value="en">English</option>
        <option value="zh-tw">繁體中文</option>
        <option value="zh-cn">简体中文</option>
        <option value="ja">日本語</option>
      </Select>
    </Container>
  );

  return [lang, _langSelect];
}

function _NFTBalance() {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { data, isError, isLoading } = useContractRead({
    address: Smartcontract.minerpunkNFT.address,
    abi: Smartcontract.minerpunkNFT.abi,
    functionName: "balanceOf",
    args: [address],
  });

  return Number(data);
}

function _IsClaimed() {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { data, isError, isLoading } = useContractRead({
    address: Smartcontract.AirdropContract.address,
    abi: Smartcontract.AirdropContract.abi,
    functionName: "isClaimed",
    watch: true,
    args: [address],
  });

  return Number(data);
}

function _IsWhitelisted() {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { data, isError, isLoading } = useContractRead({
    address: Smartcontract.AirdropContract.address,
    abi: Smartcontract.AirdropContract.abi,
    functionName: "isWhitelisted",
    watch: true,
    args: [address],
  });

  return Number(data);
}

function _TokenAmount() {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { data, isError, isLoading } = useContractRead({
    address: Smartcontract.AirdropContract.address,
    abi: Smartcontract.AirdropContract.abi,
    functionName: "tokenAmount",
    watch: true,
    args: [address],
  });

  return Number(data) / 1e18;
}

function _TotalWhitelist() {
  const { data, isError, isLoading } = useContractRead({
    address: Smartcontract.AirdropContract.address,
    abi: Smartcontract.AirdropContract.abi,
    functionName: "totalWhitelist",
    watch: true,
  });

  return Number(data);
}

function _Price() {
  const price = (_TokenAmount() * GetDCFpriceUSD()).toFixed(4);
  if (isNaN(price) == true) return "Get the highest price ... ";
  return Number(price);
}

function AirdropBoard() {
  const [lang, _langSelect] = MyLang();
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  const { address } = useAccount();
  const [addr, setaddr] = useState(0);

  const NFTBalance = _NFTBalance();
  const IsClaimed = _IsClaimed();
  const IsWhitelisted = _IsWhitelisted();
  const TokenAmount = _TokenAmount();
  const price = _Price();
  const TotalWhitelist = _TotalWhitelist();

  const MintBtn = (
    <div>
      <Link to={`/Miner/mint/${paramName}`}>
        <Button colorScheme="teal" size="md">
          Launch APP
        </Button>
      </Link>
    </div>
  );

  const Condition1 = () => {
    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!NFTBalance ? (
            <NotAllowedIcon w={4} h={4} color="red.500" />
          ) : (
            <CheckCircleIcon w={4} h={4} color="green.500" />
          )}

          <Text fontSize="lg" color="white" style={{ margin: "10px" }}>
            {" "}
            {LangPackage.airdrop.Condition01}
          </Text>
        </div>
      </>
    );
  };

  const Condition2 = () => {
    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!IsWhitelisted ? (
            <NotAllowedIcon w={4} h={4} color="red.500" />
          ) : (
            <CheckCircleIcon w={4} h={4} color="green.500" />
          )}

          <Text fontSize="lg" color="white" style={{ margin: "10px" }}>
            {" "}
            {LangPackage.airdrop.Condition02}
          </Text>
        </div>
      </>
    );
  };

  useEffect(() => {
    setaddr(address);
  }, [address]);

  const { config } = usePrepareContractWrite({
    address: Smartcontract.AirdropContract.address,
    abi: Smartcontract.AirdropContract.abi,
    functionName: "claim",
  });

  const toast = useToast();

  const TXInfo = (_title, _description, _status) => {
    toast({
      position: "top",
      title: _title,
      description: _description,
      status: _status,
      duration: 9000,
      isClosable: true,
    });
  };

  const { data, isLoading, isSuccess, write, status } =
    useContractWrite(config);

  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (waitForTransaction.isSuccess) {
      TXInfo("Success", LangPackage.evn.success, "success");
    }
  }, [waitForTransaction.isSuccess]);

  useEffect(() => {
    if (status == "error") {
      TXInfo("Error", LangPackage.evn.failDesc, "error");
    }
  }, [status]);

  return (
    <>
      <Navbar name={<Web3Button />} lang={_langSelect} />

      <section
        className="cosGradient section_style_04"
        style={{ minHeight: "100vh" }}
      >
        <Container style={{ margin: "100px 3px" }}>
          <div className="cosCard cosGradient01" style={{ overflow: "hidden" }}>
            <Row>
              <Col md="5" className="column_center">
                <img alt="AirdropScroll" src={AirdropScroll} />
              </Col>
              <Col
                md="7"
                className="column_center"
                style={{
                  minHeight: "50vh",
                  justifyContent: "space-around",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Heading as="h3" size="lg" style={{ marginTop: "30px" }}>
                  {LangPackage.airdrop.airdrop}
                </Heading>

                {!addr ? (
                  <div style={{ margin: "33px" }}>{Web3Button()}</div>
                ) : IsWhitelisted == 1 ? (
                  <div className="column_center">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img alt="Dcoff4k" width="100" src={Dcofficon} />
                      <div>
                        <div className="BoxCardWord01">
                          {TokenAmount}

                          <span
                            style={{ marginLeft: "10px", fontSize: "12px" }}
                          >
                            DCF
                          </span>
                        </div>

                        <Text fontSize="md" color="white">
                          ≈ $ {price}
                        </Text>
                      </div>
                    </div>
                    <div style={{ margin: "15px 0px" }}>
                      <Condition1 />
                      <Condition2 />
                    </div>
                    <div
                      className="row_center"
                      style={{
                        width: "280px",
                        justifyContent: "space-around",
                      }}
                    >
                      <div>
                        {IsClaimed == 1 ? (
                          <Button
                            size="md"
                            variant="solid"
                            colorScheme="teal"
                            isDisabled
                          >
                            Claimed
                          </Button>
                        ) : waitForTransaction.isLoading || isLoading ? (
                          <Button
                            size="md"
                            isLoading
                            colorScheme="teal"
                            variant="solid"
                          >
                            Email
                          </Button>
                        ) : (
                          <Button
                            size="md"
                            variant="solid"
                            colorScheme="teal"
                            isDisabled={!write}
                            onClick={() => write?.()}
                          >
                            {LangPackage.airdrop.Claim}
                          </Button>
                        )}
                      </div>
                      {MintBtn}
                    </div>
                  </div>
                ) : (
                  <div style={{ margin: "33px" }}>
                    <Text fontSize="2xl" color="white" as="b">
                      {LangPackage.airdrop.NoAirdrop}
                    </Text>
                    <div style={{ margin: "15px 0px" }}>
                      <Condition1 />
                      <Condition2 />
                    </div>
                  </div>
                )}

                <Container
                  className="row_center"
                  style={{ margin: "13px 0px" }}
                >
                  {Socialmedia("DC")}
                  {Socialmedia("IG")}
                  {Socialmedia("TG")}
                  {Socialmedia("TW")}
                  {Socialmedia("github")}
                </Container>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

function MintMiner() {
  return (
    <ChakraProvider>
      <AirdropBoard />
      <footer style={{ minHeight: "9vh", backgroundColor: "#1f202b" }}></footer>
    </ChakraProvider>
  );
}

export default MintMiner;
