import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import {
  ViewReward,
  ViewRewardETH,
  ViewRewardUSD,
  ClaimReward,
  SlideFadeEx,
  Addr,
} from "../Components/web3";
import { Outlet, Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Web3Button } from "@web3modal/react";
import { getlangselect } from "../Components/lang/langselect";
import { Container } from "react-bootstrap";
import { GetDCFpriceETH, GetDCFpriceUSD } from "../Components/pricing";
import AOS from "aos";
import Navbar from "../Components/Navbar";

import Dcofficon from "../img/myDcoff.png";
import USDicon from "../img/DollarUSD.png";
import ETHicon from "../img/ETH.png";

import {
  ChakraProvider,
  Text,
  Stat,
  StatGroup,
  Center,
  Select,
  StatNumber,
  Tabs,
} from "@chakra-ui/react";

AOS.init();

function MyLang() {
  const currentURL = window.location.href;
  const [lang, setLang] = useState("en");
  const navigate = useNavigate();
  let { paramName } = useParams();

  const url = currentURL;
  const parts = url.split("/"); // 將 URL 字串根據 '/' 符號分割成數組
  const parameter1 = parts[4]; // Miner

  const handleSelectChange = (event) => {
    const newParamName = event.target.value;
    setLang(newParamName);
    // 使用navigate进行导航，更新URL中的参数
    navigate(`/Miner/${parameter1}/${newParamName}`);
  };

  const _langSelect = (
    <Container>
      <Select
        value={paramName}
        onChange={handleSelectChange}
        color="teal"
        size="xs"
      >
        <option value="en">English</option>
        <option value="zh-tw">繁體中文</option>
        <option value="zh-cn">简体中文</option>
        <option value="ja">日本語</option>
      </Select>
    </Container>
  );

  return [lang, _langSelect];
}

function PriceBar() {
  const statgroup = (icon, price) => {
    return (
      <StatGroup>
        <Stat>
          <StatNumber>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img alt={icon} src={icon} width="25px"></img>
              <div className="cos_word">{price}</div>
            </div>
          </StatNumber>
        </Stat>
      </StatGroup>
    );
  };

  return (
    <>
      <Center>
        <div
          style={{
            width: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {statgroup(Dcofficon, "Dcoff Price")}
          {statgroup(ETHicon, GetDCFpriceETH())}
          {statgroup(USDicon, GetDCFpriceUSD())}
        </div>
      </Center>
    </>
  );
}

function Mine() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);
  return (
    <>
      <Container>
        <div className="column_center">
          <div>
            <div
              style={{
                width: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text fontSize="sm" color="#59C9C8">
                {LangPackage.Miner.MineStatus} @
              </Text>

              <div style={{ marginTop: "10px" }}>{SlideFadeEx()}</div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                className="DCFLight"
                alt="mine"
                src={Dcofficon}
                width="55px"
              ></img>
              <Text
                fontSize="sm"
                color="white"
                as="b"
                style={{
                  width: "230px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span
                  color="white"
                  style={{
                    fontSize: "30px",
                  }}
                >
                  {ViewReward()}
                </span>{" "}
                DCF
              </Text>
            </div>

            <div
              className="row_center"
              style={{
                width: "300px",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img alt="USD" src={USDicon} width="35px"></img>
                <div className="cos_word" style={{ color: "#C0C0BE" }}>
                  ${ViewRewardUSD()}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img alt="ETH" src={ETHicon} width="35px"></img>
                <div className="cos_word" style={{ color: "#C0C0BE" }}>
                  {ViewRewardETH()}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "15px" }}>{ClaimReward(paramName)}</div>
        </div>
      </Container>
    </>
  );
}

const Navigation = () => {
  const [activeLink, setActiveLink] = useState("");
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  const currentURL = window.location.href;
  const url = currentURL;
  const parts = url.split("/"); // 將 URL 字串根據 '/' 符號分割成數組
  const parameter1 = parts[4]; // Miner

  useEffect(() => {
    // 如果URL的paramName是一個我們知道的連結名稱，則設定它作為activeLink
    if (["Mint", "Income", "token", "badges"].includes(parameter1)) {
      setActiveLink(parameter1);
    }
  }, [parameter1]); // 依賴於paramName以便當它更改時重新執行此效果

  const handleLinkClick = (link) => {
    setActiveLink(link);
    // 在點擊連結時將選擇的連結狀態保存到 localStorage
    localStorage.setItem("activeLink", link);
  };

  return (
    <nav className="navigation">
      <ul>
        <li className={activeLink === "Mint" ? "active" : ""}>
          <Link
            to={`/Miner/Mint/${paramName}`}
            onClick={() => handleLinkClick("Mint")}
          >
            {LangPackage.Mint.title}
          </Link>
        </li>
        <li className={activeLink === "Income" ? "active" : ""}>
          <Link
            to={`/Miner/Income/${paramName}`}
            onClick={() => handleLinkClick("Income")}
          >
            {LangPackage.Mine.title}
          </Link>
        </li>
        <li className={activeLink === "token" ? "active" : ""}>
          <Link
            to={`/Miner/token/${paramName}`}
            onClick={() => handleLinkClick("token")}
          >
            {LangPackage.Token.title}
          </Link>
        </li>
        <li className={activeLink === "badges" ? "active" : ""}>
          <Link
            to={`/Miner/badges/${paramName}`}
            onClick={() => handleLinkClick("badges")}
          >
            {LangPackage.badge.title}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

function HomeTabs() {
  return (
    <>
      <Tabs position="relative" variant="unstyled">
        <Container className="center">{Navigation()}</Container>
        <div id="detail" className="row_center " style={{ color: "white" }}>
          <Outlet />
        </div>
      </Tabs>
    </>
  );
}

function MintMiner() {
  const [lang, _langSelect] = MyLang();

  const _address = Addr();

  return (
    <>
      <ChakraProvider>
        <div>
          <div style={{ backgroundColor: "#1f202b" }}>
            <Navbar name={<Web3Button />} lang={_langSelect} />
            <div className="section_style_05">
              <div className="column_center MintminerHeight">
                {!_address ? (
                  <div className="content center cosShadow">
                    <Web3Button />
                  </div>
                ) : (
                  <></>
                )}

                <PriceBar />
                <div style={{ marginTop: "60px" }}>{Mine()}</div>
              </div>
            </div>
            {HomeTabs()}
          </div>

          <footer
            style={{ minHeight: "9vh", backgroundColor: "#1f202b" }}
          ></footer>
        </div>
      </ChakraProvider>
    </>
  );
}

export default MintMiner;
