import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import { getSmartContract } from "../SmartContract";
import { getlangselect } from "../Components/lang/langselect";
import { Socialmedia } from "../Components/Socialmedia";
import { _Percent } from "../Components/Tool";
import { truncateAddress } from "../utils";
import { ERC1155balance, NFTBalance } from "../Components/web3";

import USDTimg from "../img/tether-usdt-logo.png";

import Navbar from "../Components/Navbar";

import {
  ChakraProvider,
  useToast,
  Select,
  Button,
  Text,
  Heading,
  Center,
} from "@chakra-ui/react";

import {
  CheckCircleIcon,
  NotAllowedIcon,
  ArrowForwardIcon,
  WarningIcon,
} from "@chakra-ui/icons";

import { Web3Button } from "@web3modal/react";

import {
  useContractRead,
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

const Smartcontract = getSmartContract();

function MyLang() {
  const [lang, setLang] = useState("en");
  const navigate = useNavigate();
  let { paramName } = useParams();

  const handleSelectChange = (event) => {
    const newParamName = event.target.value;
    setLang(newParamName);
    // 使用navigate进行导航，更新URL中的参数
    navigate(`/cashbox/${newParamName}`);
  };

  const _langSelect = (
    <Container>
      <Select
        value={paramName}
        onChange={handleSelectChange}
        color="teal"
        size="xs"
      >
        <option value="en">English</option>
        <option value="zh-tw">繁體中文</option>
        <option value="zh-cn">简体中文</option>
        <option value="ja">日本語</option>
      </Select>
    </Container>
  );

  return [lang, _langSelect];
}

function _ViewAchievementStatus(i) {
  const { data } = useContractRead({
    address: Smartcontract.CashBox.address,
    abi: Smartcontract.CashBox.abi,
    functionName: "ViewAchievementStatus",
    args: [i],
  });

  return Number(data);
}

function Condition() {
  let ConditionArray = [];
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  for (let i = 1; i <= 3; i++) {
    if (_ViewAchievementStatus(i) == 1) {
      let status = {
        RulesNumber: i,
      };
      ConditionArray.push(status);
    }
  }

  const userAssets = {
    1: {
      balance: ERC1155balance(1),
      info: LangPackage.cashbox.Uptostandard01,
      fail: (
        <Link to={`/freemint/${paramName}`}>
          {LangPackage.cashbox.notuptostandard01}
        </Link>
      ),
    },
    2: {
      balance: NFTBalance(),
      info: LangPackage.cashbox.Uptostandard02,
      fail: (
        <Link to={`/Miner/mint/${paramName}`}>
          {LangPackage.cashbox.notuptostandard02}
        </Link>
      ),
    },
    3: {
      balance: ERC1155balance(5),
      info: LangPackage.cashbox.Uptostandard03,
      fail: (
        <Link to={`/Miner/badges/${paramName}`}>
          {LangPackage.cashbox.notuptostandard03}
        </Link>
      ),
    },
  };

  return (
    <>
      {ConditionArray.length == 0 ? (
        <div className="depictionCard cosShadow column_center">
          <Text color="white" as="b" fontSize="lg" style={{ margin: "15px" }}>
            {LangPackage.cashbox.unconditional}
          </Text>
        </div>
      ) : (
        ConditionArray.map((item, index) => (
          <Container className="depictionCard cosShadow">
            <React.Fragment key={index}>
              {userAssets[item.RulesNumber].balance > 0 ? (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CheckCircleIcon
                      w={4}
                      h={4}
                      color="green.500"
                      style={{ margin: "0px 10px" }}
                    />
                    <div style={{ marginTop: "13px", marginLeft: "18px" }}>
                      <Text color="white">
                        {userAssets[item.RulesNumber].info}{" "}
                      </Text>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <NotAllowedIcon
                      w={4}
                      h={4}
                      color="red.500"
                      style={{ margin: "0px 10px" }}
                    />
                    <div style={{ marginTop: "13px", marginLeft: "18px" }}>
                      <Text color="white">
                        {userAssets[item.RulesNumber].fail} <ArrowForwardIcon />
                      </Text>
                    </div>
                  </div>
                </>
              )}
            </React.Fragment>
          </Container>
        ))
      )}
      {/* Waring */}
      <Container
        className="depictionCard cosShadow"
        style={{ display: "flex", alignItems: "center" }}
      >
        <WarningIcon
          w={4}
          h={4}
          color="yellow.500"
          style={{ margin: "0px 10px" }}
        />
        <Text
          color="white"
          as="b"
          fontSize="sm"
          style={{ margin: "15px", marginLeft: "18px" }}
        >
          {LangPackage.cashbox.warning}
        </Text>
      </Container>
    </>
  );
}

function _ViewRound() {
  const { data } = useContractRead({
    address: Smartcontract.CashBox.address,
    abi: Smartcontract.CashBox.abi,
    functionName: "ViewRound",
    watch: true,
  });

  return Number(data);
}

function _USDbalances() {
  const { data } = useContractRead({
    address: Smartcontract.USDT.address,
    abi: Smartcontract.USDT.abi,
    functionName: "balances",
    watch: true,
    args: ["0x22655c52B3C6Aff1e4614E92d72F8f4391E9D977"],
  });

  return Number(data);
}

function _ViewUSDTamount() {
  const { data } = useContractRead({
    address: Smartcontract.CashBox.address,
    abi: Smartcontract.CashBox.abi,
    functionName: "ViewUSDTamount",
    watch: true,
  });

  return Number(data) / 10 ** 6;
}

function _ViewopeningHours() {
  const { data } = useContractRead({
    address: Smartcontract.CashBox.address,
    abi: Smartcontract.CashBox.abi,
    functionName: "ViewopeningHours",
    watch: true,
  });

  return Number(data);
}

function _ViewroundParticipation() {
  let round = _ViewRound();
  const { address } = useAccount();
  const { data, isError, isLoading } = useContractRead({
    address: Smartcontract.CashBox.address,
    abi: Smartcontract.CashBox.abi,
    functionName: "ViewroundParticipation",
    watch: true,
    args: [address, round],
  });

  return data;
}

function _Viewacquirer() {
  let round = _ViewRound();
  const { data } = useContractRead({
    address: Smartcontract.CashBox.address,
    abi: Smartcontract.CashBox.abi,
    functionName: "Viewacquirer",
    watch: true,
    args: [round],
  });

  return data;
}

function _Viewtime() {
  const { data, isError, isLoading } = useContractRead({
    address: Smartcontract.CashBox.address,
    abi: Smartcontract.CashBox.abi,
    functionName: "Viewtime",
    watch: true,
  });

  return Number(data);
}

function CountdownTimer({ unixTime }) {
  const calculateTimeLeft = () => {
    const difference = unixTime - Math.floor(Date.now() / 1000);

    let timeLeft = {
      Days: 0,
      Hours: 0,
      minutes: 0,
      Seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        Days: Math.floor(difference / (60 * 60 * 24)),
        Hours: Math.floor((difference / (60 * 60)) % 24),
        minutes: Math.floor((difference / 60) % 60),
        Seconds: Math.floor(difference % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {Object.entries(timeLeft).map(([unit, value]) => (
        <span key={unit}>
          <div className="column_center">
            <Text
              color="white"
              className="cosCard cosGradient center BoxCardWord "
              style={{ margin: "8px" }}
            >
              {value}
            </Text>{" "}
            <Text fontSize="md" color="white" style={{ margin: "10px" }}>
              {unit}
            </Text>{" "}
          </div>
        </span>
      ))}
    </div>
  );
}

function CashBox() {
  const [lang, _langSelect] = MyLang();
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  const { address } = useAccount();
  const roundParticipation = _ViewroundParticipation();

  const MintBtn = (
    <div>
      <Link to={`/freemint/${paramName}`}>
        <Button
          colorScheme="teal"
          size="md"
          style={{ width: "350px", marginTop: "10px", height: "50px" }}
        >
          Free Mint
        </Button>
      </Link>
    </div>
  );

  const toast = useToast();
  const TXInfo = (_title, _description, _status) => {
    toast({
      position: "top",
      title: _title,
      description: _description,
      status: _status,
      duration: 9000,
      isClosable: true,
    });
  };

  const { config } = usePrepareContractWrite({
    address: Smartcontract.CashBox.address,
    abi: Smartcontract.CashBox.abi,
    functionName: "USDTGrabber",
  });

  const { data, isLoading, isSuccess, write, status } =
    useContractWrite(config);

  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (waitForTransaction.isSuccess) {
      TXInfo("Success", LangPackage.evn.success, "success");
    }
  }, [waitForTransaction.isSuccess]);

  useEffect(() => {
    if (status == "error") {
      TXInfo("Error", LangPackage.evn.failDesc, "error");
    }
  }, [status]);

  const ClaimBtn = ({ unixTime }) => {
    const calculateTimeLeft = () => {
      const difference = unixTime - Math.floor(Date.now() / 1000);

      let timeLeft = {
        D: 0,
        H: 0,
        M: 0,
        S: 0,
      };

      if (difference > 0) {
        timeLeft = {
          D: Math.floor(difference / (60 * 60 * 24)),
          H: Math.floor((difference / (60 * 60)) % 24),
          M: Math.floor((difference / 60) % 60),
          S: Math.floor(difference % 60),
        };
      }

      return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [timerEnd, setTimerEnd] = useState(false); // Add new state variable for checking if timer is ended

    useEffect(() => {
      const timer = setTimeout(() => {
        const time = calculateTimeLeft();
        if (time.D <= 0 && time.H <= 0 && time.M <= 0 && time.S <= 0) {
          setTimerEnd(true);
        }
        setTimeLeft(time);
      }, 1000);

      return () => clearTimeout(timer);
    });

    const formatTime = (value) => {
      return String(value).padStart(2, "0");
    };

    const timer = () => {
      return (
        <>
          {formatTime(timeLeft.D)}:{formatTime(timeLeft.H)}:
          {formatTime(timeLeft.M)}:{formatTime(timeLeft.S)}
        </>
      );
    };

    return (
      <>
        <div>
          {!address ? (
            Web3Button()
          ) : waitForTransaction.isLoading || isLoading ? (
            <Button
              size="lg"
              isLoading
              colorScheme="teal"
              variant="solid"
              width="350px"
            >
              Email
            </Button>
          ) : roundParticipation ? (
            <Button
              size="lg"
              variant="solid"
              colorScheme="yellow"
              width="350px"
              isDisabled
            >
              {LangPackage.cashbox.claimed}
            </Button>
          ) : !timerEnd ? (
            <>
              {" "}
              <Button
                size="lg"
                variant="solid"
                colorScheme="teal"
                isDisabled
                width="350px"
              >
                {timer()}
              </Button>
            </>
          ) : (
            <>
              <Button
                size="lg"
                variant="solid"
                colorScheme="teal"
                isDisabled={!write}
                onClick={() => write?.()}
                width="350px"
              >
                {LangPackage.cashbox.claim}
              </Button>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Navbar name={<Web3Button />} lang={_langSelect} />


      <section className="section_style_05">
        <Container className="center" style={{ height: "120vh" }}>
          <Col md="12" className="column_center">
            <div>
              <Text color="white" fontSize="md" style={{ marginLeft: "10px" }}>
                {LangPackage.cashbox.round} : {_ViewRound()}
              </Text>
              <div>
                <CountdownTimer unixTime={_ViewopeningHours()} />
              </div>
              {/* rules */}
              <div className="depictionCard contentA center cosShadow">
                <div className="rulesText">{LangPackage.cashbox.rules}</div>
              </div>
              {/* Winner */}
              <div className="Winner" style={{ marginTop: "320px " }}>
                {LangPackage.cashbox.winner} :{" "}
                {_Viewacquirer() == "0xbc1ADfF2EAC8dbA22d382DFADDf7e6B73aBC2334"
                  ? "0x0000...0000"
                  : truncateAddress(_Viewacquirer())}
              </div>
            </div>
          </Col>
        </Container>
        <Container>
          {_USDbalances() == 0 ? (
            <div className="column_center">
              <Center color="white" fontSize="xl">
                {LangPackage.cashbox.end}
              </Center>
              <Container className="row_center" style={{ margin: "13px 0px" }}>
                {Socialmedia("DC")}
                {Socialmedia("IG")}
                {Socialmedia("TG")}
                {Socialmedia("TW")}
                {Socialmedia("github")}
              </Container>
            </div>
          ) : (
            <>
              <Row>
                <Col md="6" className="column_center">
                  {" "}
                  <div
                    className="column_center"
                    style={{ marginBottom: "50px" }}
                  >
                    <img
                      className="DCFLight"
                      alt="USDTimg"
                      src={USDTimg}
                      width="150"
                    />
                    <Text as="b" color="white" className="Winner">
                      {_ViewUSDTamount()} USDT
                    </Text>
                  </div>
                </Col>
                <Col md="6" className="column_center">
                  <div>
                    <Text color="white" fontSize="lg" as="b">
                      {LangPackage.cashbox.Condition} :
                    </Text>
                    <div className="column_center">
                      <div className="center">
                        <div>{Condition()}</div>
                      </div>

                      <div>
                        <ClaimBtn unixTime={_ViewopeningHours()} />
                      </div>
                      <div>{MintBtn}</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </section>
    </>
  );
}

function Cashbox() {
  return (
    <ChakraProvider>
      <CashBox />
      <footer
        style={{ minHeight: "20vh", backgroundColor: "#1f202b" }}
      ></footer>
    </ChakraProvider>
  );
}

export default Cashbox;
