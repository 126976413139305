import { useState, useEffect } from "react";
import { Badgebar } from "./web3";
import { useParams } from "react-router-dom";

const BadgebarModel = () => {
  let { paramName } = useParams();
  const BadgebarComponent = Badgebar(paramName);
  return BadgebarComponent;
};

export default BadgebarModel;
