import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import AOS from "aos";
import "../App.css";

import { ChakraProvider, Text } from "@chakra-ui/react";
import { Container, Row, Col } from "react-bootstrap";

import logo from "../img/logo.png";

AOS.init();

function footer() {
  return (
    <ChakraProvider>
      <div
        className="center"
        style={{ backgroundColor: "black", minHeight: "40vh" }}
      >
        <Container>
          <Row>
            <Col md="2" className="center">
              <img
                alt="minerpunk"
                src={logo}
                width="130"
                className="d-inline-block align-center "
                style={{ margin: "30px" }}
              />
            </Col>
            <Col md="10" className="center">
              <Container>
                <Row className="row_center" style={{ color: "white" }}>
                  <Col md="2" className="center">
                    <a
                      href="https://snapshot.org/#/minerpunk.eth"
                      target="_blank"
                    >
                      Governance
                    </a>
                  </Col>
                  <Col md="2" className="center">
                    <a
                      href="https://app.uniswap.org/#/swap?outputCurrency=0xC74Ab3FF349cE64a349854BcD5dc2D542371350F"
                      target="_blank"
                    >
                      Get Token
                    </a>
                  </Col>
                  <Col md="2" className="center">
                    <a
                      href="https://to101380.gitbook.io/minerpunk/policy/privacy-policy/"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </Col>
                  <Col md="2" className="center">
                    <a
                      href="https://to101380.gitbook.io/minerpunk/"
                      target="_blank"
                    >
                      White Pepper
                    </a>
                  </Col>
                  <Col md="2" className="center">
                    <a
                      href="https://to101380.gitbook.io/minerpunk/"
                      target="_blank"
                    >
                      Security
                    </a>
                  </Col>
                  <Col md="2" className="center">
                    <a
                      href="https://www.linkedin.com/company/minerpunk/"
                      target="_blank"
                    >
                      Linkedin
                    </a>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </ChakraProvider>
  );
}

export default footer;
