import { useState, useEffect } from "react";
import { IncomeBoard } from "./web3";
import { useParams } from "react-router-dom";

const IncomeBoardModel = () => {
  let { paramName } = useParams();
  const IncomeBoardComponent = IncomeBoard(paramName);
  return IncomeBoardComponent;
};

export default IncomeBoardModel;
