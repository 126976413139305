import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AOS from "aos";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import { getlangselect } from "../Components/lang/langselect";
import { Socialmedia } from "../Components/Socialmedia";
import Navbar from "../Components/Navbar";
import generateimg from "../img/generateimg.png";
import founder from "../img/WeberImg.jpg";
import mainLogo from "../img/mainLogo.png";
import LogoWord from "../img/DcofferLogoWord.png";
import storyImg01 from "../img/story/storyImg01.png";
import storyImg02 from "../img/story/storyImg02.png";
import storyImg03 from "../img/story/storyImg03.png";
import storyImg04 from "../img/story/storyImg04.png";
import storyImg05 from "../img/story/storyImg05.png";

import {
  ChakraProvider,
  useToast,
  Select,
  Button,
  Text,
  Center,
  Heading,
} from "@chakra-ui/react";

AOS.init();

function MyLang() {
  const [lang, setLang] = useState("en");
  const navigate = useNavigate();
  let { paramName } = useParams();

  const handleSelectChange = (event) => {
    const newParamName = event.target.value;
    setLang(newParamName);
    // 使用navigate进行导航，更新URL中的参数
    navigate(`/brandstory/${newParamName}`);
  };

  const _langSelect = (
    <Container>
      <Select
        value={paramName}
        onChange={handleSelectChange}
        color="teal"
        size="xs"
      >
        <option value="en">English</option>
        <option value="zh-tw">繁體中文</option>
        <option value="zh-cn">简体中文</option>
        <option value="ja">日本語</option>
      </Select>
    </Container>
  );

  return [lang, _langSelect];
}

function Brandstory() {
  const [lang, _langSelect] = MyLang();
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  const MintBtn = (w, h) => {
    return (
      <div>
        <Link to={`/Miner/mint/${paramName}`}>
          <Button
            colorScheme="teal"
            size="lg"
            style={{ margin: "15px 0px", width: w, height: h }}
          >
            Launch APP
          </Button>
        </Link>
      </div>
    );
  };

  const BackgroundDiv = ({ Chapter, imageUrl }) => {
    return (
      <div
        style={{
          backgroundImage: `linear-gradient(to top, rgba(31, 32, 43, 1), rgba(10, 10, 17, 0.01),rgba(31, 32, 43, 1)), url('${imageUrl}')`,
          height: "90vh",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          margin: "40px 0px",
        }}
      >
        <Text
          className="StoryWord"
          fontSize="lg"
          color="white"
          as="b"
          data-aos="zoom-in"
        >
          {LangPackage.brandstory[Chapter]}
        </Text>
      </div>
    );
  };

  return (
    <>
      <Navbar name={MintBtn("120px", "30px")} lang={_langSelect} />

      <section className="section_style_06">
        <img
          data-aos="fade-up"
          data-aos-duration="1000"
          alt={mainLogo}
          width="150"
          src={mainLogo}
        />
        <img
          data-aos="fade-up"
          data-aos-duration="1000"
          alt={LogoWord}
          width="150"
          src={LogoWord}
        />

        <Text
          fontSize="lg"
          color="white"
          data-aos="fade-up"
          data-aos-duration="1000"
          style={{ margin: "15px 15px" }}
        >
          {LangPackage.brandstory.Present}
        </Text>
      </section>

      <BackgroundDiv
        Chapter={"chapter01"}
        imageUrl={"https://i.imgur.com/HSo0JNa.png"}
      />
      <BackgroundDiv
        Chapter={"chapter02"}
        imageUrl={"https://i.imgur.com/qwH0b1a.png"}
      />
      <BackgroundDiv
        Chapter={"chapter03"}
        imageUrl={"https://i.imgur.com/WqvL19G.png"}
      />
      <BackgroundDiv
        Chapter={"chapter04"}
        imageUrl={"https://i.imgur.com/ZLpHijn.png"}
      />

      <section className="cosGradient section_style_00">
        {MintBtn("320px", "60px")}

        <div className="mouse_scroll">
          <div className="mouse">
            <div className="wheel"></div>
          </div>
          <div>
            <span className="m_scroll_arrows unu"></span>
            <span className="m_scroll_arrows doi"></span>
            <span className="m_scroll_arrows trei"></span>
          </div>
        </div>
      </section>

      <section className="cosGradient section_style_10">
        <img
          data-aos="fade-up"
          data-aos-duration="1000"
          alt={mainLogo}
          width="150"
          src={mainLogo}
        />
        <img
          data-aos="fade-up"
          data-aos-duration="1000"
          alt={LogoWord}
          width="150"
          src={LogoWord}
        />

        <Text
          fontSize="lg"
          color="white"
          data-aos="fade-up"
          data-aos-duration="1000"
          style={{ margin: "15px 15px" }}
        >
          {LangPackage.brandstory.rumor}
        </Text>
        <Text
          fontSize="2xl"
          color="white"
          as="b"
          data-aos="fade-up"
          data-aos-duration="1000"
          style={{ margin: "3px 15px" }}
        >
          {LangPackage.brandstory.rumorTitle}
        </Text>
      </section>

      <BackgroundDiv
        Chapter={"rumorChapter01"}
        imageUrl={"https://i.imgur.com/0wDCj4O.jpg"}
      />
      <BackgroundDiv
        Chapter={"rumorChapter02"}
        imageUrl={
          "https://cdn.midjourney.com/58ec7e64-cd24-483f-b031-54165185c152/0_3.png"
        }
      />
      <BackgroundDiv
        Chapter={"rumorChapter03"}
        imageUrl={
          "https://cdn.midjourney.com/0572edc4-708c-4fa1-8d2e-0549ca2f2f0a/0_2.png"
        }
      />
    </>
  );
}

function BrandStory() {
  return (
    <ChakraProvider>
      <div style={{ backgroundColor: "#1f202b" }}>
        <Brandstory />
        <footer
          style={{ minHeight: "20vh", backgroundColor: "#1f202b" }}
        ></footer>
      </div>
    </ChakraProvider>
  );
}

export default BrandStory;
