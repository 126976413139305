import { useState, useEffect } from "react";
import { MintCard } from "./web3";
import { useParams } from "react-router-dom";

const MintModel = () => {
  let { paramName } = useParams();
  const mintCardComponent = MintCard(paramName);
  return mintCardComponent;
};

export default MintModel;
