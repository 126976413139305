import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import { getSmartContract } from "../SmartContract";
import { getlangselect } from "../Components/lang/langselect";
import { Socialmedia } from "../Components/Socialmedia";
import { _Percent } from "../Components/Tool";
import Navbar from "../Components/Navbar";
import { IntroA, IntroB, IntroC, IntroD, IntroE } from "../Components/NFTintro";
import Dcofficon from "../img/Dcoff4K.png";

import {
  ChakraProvider,
  useToast,
  Select,
  Button,
  Text,
} from "@chakra-ui/react";

import {
  useContractRead,
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

import { Web3Button } from "@web3modal/react";

const Smartcontract = getSmartContract();

function MyLang() {
  const [lang, setLang] = useState("en");
  const navigate = useNavigate();
  let { paramName } = useParams();

  const handleSelectChange = (event) => {
    const newParamName = event.target.value;
    setLang(newParamName);
    // 使用navigate进行导航，更新URL中的参数
    navigate(`/freemint/${newParamName}`);
  };

  const _langSelect = (
    <Container>
      <Select
        value={paramName}
        onChange={handleSelectChange}
        color="teal"
        size="xs"
      >
        <option value="en">English</option>
        <option value="zh-tw">繁體中文</option>
        <option value="zh-cn">简体中文</option>
        <option value="ja">日本語</option>
      </Select>
    </Container>
  );

  return [lang, _langSelect];
}

function ERC1155balance() {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.BadgesERC1155.address,
    abi: Smartcontract.BadgesERC1155.abi,
    functionName: "balanceOf",
    args: [address, 1],
  });
  return Number(data);
}

function FeeMint() {
  const [lang, _langSelect] = MyLang();
  let { paramName } = useParams();

  const LangPackage = getlangselect(paramName);
  const { address } = useAccount();

  const MintBtn = (
    <div>
      <Link to={`/Miner/mint/${paramName}`}>
        <Button
          colorScheme="teal"
          size="lg"
          style={{ margin: "15px 0px", width: "300px" }}
        >
          Launch APP
        </Button>
      </Link>
    </div>
  );

  const isClaimed = ERC1155balance();
  const looksrareERC1155 = Socialmedia("lookrares_ERC1155");
  const X2Y2ERC1155 = Socialmedia("X2Y2_ERC1155");

  const { config } = usePrepareContractWrite({
    address: Smartcontract.BadgesController.address,
    abi: Smartcontract.BadgesController.abi,
    functionName: "AwardBadge",
    args: [1],
  });

  const toast = useToast();

  const TXInfo = (_title, _description, _status) => {
    toast({
      position: "top",
      title: _title,
      description: _description,
      status: _status,
      duration: 9000,
      isClosable: true,
    });
  };

  const { data, write, status } = useContractWrite(config);

  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (waitForTransaction.isSuccess) {
      TXInfo("Success", LangPackage.evn.success, "success");
    }
  }, [waitForTransaction.isSuccess]);

  useEffect(() => {
    if (status == "error") {
      TXInfo("Error", LangPackage.evn.failDesc, "error");
    }
  }, [status]);

  return (
    <>
      <Navbar name={<Web3Button />} lang={_langSelect} />
      <section
        className="section_style_01"
        style={{ backgroundColor: "black" }}
      >
        <Container className="column_center" style={{ height: "100vh" }}>
          <div data-aos="fade-up" data-aos-duration="3000">
            {LangPackage.home.head1}
          </div>
          <div>
            <div className="column_center">
              <div className="mouse_scroll">
                <div className="mouse">
                  <div className="wheel"></div>
                </div>
                <div>
                  <span className="m_scroll_arrows unu"></span>
                  <span className="m_scroll_arrows doi"></span>
                  <span className="m_scroll_arrows trei"></span>
                </div>
              </div>
            </div>
          </div>
        </Container>

        {IntroA(paramName)}
        {IntroB(paramName)}
        {IntroC(paramName)}
        {IntroD(paramName)}
        {IntroE(paramName)}
        <div className="column_center" style={{ height: "100vh" }}>
          <Text fontSize="50px" color="white">
            Free Mint !
          </Text>
          <img
            alt="Dcofficon"
            src={Dcofficon}
            width="305px"
            className="cosCard cosGradient"
            style={{
              transition: " 200ms",
              margin: "25px 0px ",
            }}
          />
          {address ? (
            !isClaimed ? (
              <Button
                className="cosShadow"
                colorScheme="teal"
                size="lg"
                isDisabled={!write}
                onClick={() => write?.()}
                style={{ width: "300px" }}
              >
                Free Mint
              </Button>
            ) : (
              <div
                style={{
                  justifyContent: "space-around",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "300px",
                }}
              >
                <div className="cos_word" style={{ fontSize: "20px" }}>
                  Claimed
                </div>

                <div
                  style={{
                    justifyContent: "space-around",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {X2Y2ERC1155}
                  {looksrareERC1155}
                </div>
              </div>
            )
          ) : (
            <Web3Button />
          )}
          {MintBtn}
          <Container className="row_center" style={{ margin: "13px 0px" }}>
            {Socialmedia("DC")}
            {Socialmedia("IG")}
            {Socialmedia("TG")}
            {Socialmedia("TW")}
            {Socialmedia("github")}
          </Container>
          <Text fontSize="xs" color="white">
            {" "}
            Follow and get more Giveaway !!!
          </Text>
        </div>
      </section>
    </>
  );
}

function MintMiner() {
  return (
    <ChakraProvider>
      <FeeMint />
      <footer style={{ minHeight: "9vh", backgroundColor: "#000000" }}></footer>
    </ChakraProvider>
  );
}

export default MintMiner;
