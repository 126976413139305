import { useState, useEffect } from "react";
import { TokenInfo } from "./web3";
import { useParams } from "react-router-dom";

const TokenInfoModel = () => {
  let { paramName } = useParams();
  const TokenInfoComponent = TokenInfo(paramName);
  return TokenInfoComponent;
};

export default TokenInfoModel;
