export function BlockStatus(BlockNum, blockheight) {
  const _K = 1500000 * 80000000e18;
  const _startBlockNumber = 16292444;
  let Stepcount = BlockNum + blockheight - _startBlockNumber + 1500000;
  let TokenWaterLevel = _K / Stepcount / 1e18;

  return TokenWaterLevel;
}


//Minerpunk Dungeon BlockStatus
export function MDBlockStatus(BlockNum, blockheight) {
  const _K = 15000 * 5000000e18;
  const _startBlockNumber = 17968296;
  let Stepcount = BlockNum + blockheight - _startBlockNumber + 15000;
  let TokenWaterLevel = _K / Stepcount / 1e18;

  return TokenWaterLevel;
}
