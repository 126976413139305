const langselect = {
  "zh-tw": {
    home: {
      head1: "鑄造一隻 Minerpunk ， 讓他為你挖礦。",
      section1:
        "每隻 MinerPunk都是獨一無二的。  MinerPunk 作為 ERC-721A 存儲在以太坊區塊鏈並託管在 IPFS 。",
      noStaking:
        "更重要的是，無需授權，無需質押，持有即挖礦。資產更安全、更乾淨、更簡單。",
      head2: "每一隻MinerPunk都有算力，累積你的算力，可以挖出更多的礦。",
      section2:
        "每一隻矮人都賦予 1~9 分的算力，並將metadata同步在智能合約及IPFS上，你可以在具備解析metadata的平台上看見每隻MinerPunk的算力，例如 X2Y2、looksrare。",

      head3: "如何挖礦? 挖什麼礦?",
      section3:
        "DCOFF 是一種ERC20的代幣，由ETH作為儲備，提供在去中心化交易所交易。有80%的代幣初始是鎖在智能合約裡，必須由MinerPunk們去挖掘，隨著時間的推移，挖礦難度會持續提升，同樣的算力能挖出的數量也會開始變少。",

      head4: "持有 MinerPunk ，成為 MinerPunk 的公民。",
      section4:
        "持有MinerPunk代幣可參與重大決策，享受公民福利，並獲得空投獎勵。您也有責任監控MinerPunk以防止詐騙活動。讓我們共同治理這個平台，使其更和平、更強大，像一個能保護您的國家。",

      head5: "一種結合挖礦、Defi、代幣經濟、DAO為一體的NFT。",
      section5: "GET ME IF YOU CAN.",
      brandstory: "奇幻故事",
    },
    Miner: {
      DcoffPrice: "Dcoff 價格",
      MineStatus: "挖礦狀態",
      Claim: "領取",
    },
    Mint: {
      title: "鑄造",
      MaxSupply: "發行總量",
      Inventory: "庫存",
      Open: "開放",
      Sold: "已售",
      Insufficientbalance: "餘額不足",
      SoldOut: "售完",
      SecurityCheck: "正在安全性檢查，請稍後。",
      FeatureA: "持有即挖礦，不需授權。",
      FeatureB: "每隻都是獨一無二。",
      FeatureC: "圖片都託管至IPFS。",
      FeatureD: "以ERC721A做為標準。",
    },
    Mine: {
      title: "挖礦",
      MinePower: "挖礦算力",
      NFTBalance: "NFT 餘額",
      EnterDungeon: "進入地下城",
      fetchDifficulty: "正在計算當前的挖礦難度...",
      dugupintro: "當前區塊挖出的代幣總和",
      ExpectedDailyReturn: "預期日收益",
      ExpectedMonthlyReturn: "預期月收益",
      ExpectedAnnualReturn: "預期年收益",
      Difficulty: "挖礦難度",
    },
    Token: {
      title: "金礦",
      Balance: "金礦餘額",
      price: "價格",
      MaxSupply: "最高發行量",
      TotalSupply: "當前發行總量",
      CirculatingSupply: "流動在外數量",
      Locked: "鎖在合約數量",
      Burned: "已燒毀數量",
      CONTRACTADDRESS: "合約地址",
    },

    airdrop: {
      airdrop: "空投",
      NoAirdrop: "對不起 ！你沒有任何空投。",
      Claim: "提領",
      Condition01: "擁有一隻MinerPunk的NFT",
      Condition02: "被邀請進入白名單。",
    },
    badge: {
      title: "徽章",
      tab: {
        task: "條件",
        directions: "說明",
      },
      messages: {
        1: "你發現了一顆閃閃發亮的礦石，這是個難得的機會！快拿起它，也許會帶來好運！",
        2: "當你的DCF數量達到5000枚時，你將獲得DCF守護者的稱號。這是你努力的成果，也是對Minerpunk世界的貢獻。",
        3: "除了挖礦，你還有一個不可思議的技能：魔法！你可以破解智能合約，打開時間鎖。你可以到社群尋找線索，或者直接到這裡開始破解。",
        4: "當你的算力達到20，代表你已經在Minerpunk世界裡做出了巨大貢獻。你的矮人們非常出色！",
        5: "恭喜你！你已經獲得前四個徽章，成為我們的高級會員。你將獲得更多福利和禮物，同時也將享有無上的光榮！",
      },
      completeinfo: {
        1: "成功取得",
        2: "5000 / 5000 DCF",
        3: "成功破解",
        4: "20/20 Power",
        5: "5/5 Badges",
      },

      badge01title: "Dcoff",
      badge01description:
        "在Minerpunk矮人的世界裡，有一種珍貴的礦石叫做Dcoff。這種礦石由以太坊組成，是Minerpunk矮人社會的主要能源來源。Minerpunk矮人依賴Dcoff礦石維持日常生活，因為它提供了所需的能量來驅動城市和機器，並成為他們工業和技術的基礎。每當在礦山中發現新的礦脈，Minerpunk人民就會聚集在一起開始開採這種寶貴的資源。",
      badge02title: "DCOFF 守護者",
      badge02description:
        "DCOFF 是一種稀有且重要的能源來源，被視為未來文明發展的關鍵因素。在Minerpunk中，許多矮人爭奪和保護它以建立自己的文明。",
      badge03title: "時間膠囊",
      badge03description:
        "時間膠囊是一個神秘的盒子，被時間魔法鎖住，只有真正的天才才能破解它的密碼。許多人追尋這個傳說中的寶藏，但很少有人能解決它的難題。在Minerpunk的世界中，它被認為是最具挑戰性的任務之一，並且深受尊敬。",
      badge04title: "文明推進者",
      badge04description:
        "你的矮人是采礦和資源管理的專家，他們擁有獨特的技能和能力來開採更多的DCOFF礦石，為Minerpunk文明的發展做出貢獻。他們的勞動和努力對社會的進步至關重要，因為DCOFF是未來文明的關鍵能源來源，你的矮人的貢獻將被記錄在歷史中。",
      badge05title: "Minerpunk俱樂部",
      badge05description:
        "在Minerpunk社群中取得最高的貢獻是一個難以置信的成就，你的努力和付出為文明的發展做出了優秀的貢獻。你不僅是一個優秀的成員，更是潮流的引領者。 你的成功不僅是你自己的，也是 Minerpunk 社區所有成員的集體榮譽。",
    },
    cashbox: {
      round: "回合數",
      winner: "獲勝者",
      rules:
        "當倒數計時器歸零時，按下提領鍵便可搶奪存放在合約裡的USDT。每次只有一名參加者能抽中，當參加者提領成功時，開放時間就會往後延長1小時，直到合約裡的USDT歸零，競賽便結束。",
      warning:
        "此次活動屬於競賽性質，無論您有無獲獎，gas fee都必須自己承擔，若有任何疑慮請勿參與。",
      Condition: "參與的條件",
      unconditional: "無須任何條件",
      Uptostandard01: "擁有Minerpunk Badges #001 的NFT。",
      Uptostandard02: "擁有Minerpunk 的NFT。",
      Uptostandard03: "擁有Minerpunk Badges #005 的NFT。",
      notuptostandard01: "必須先擁有Minerpunk Badges #001 的NFT。",
      notuptostandard02: "必須先擁有Minerpunk 的NFT。",
      notuptostandard03: "必須先擁有Minerpunk Badges #005 的NFT。",
      claim: "提領",
      claimed: "恭喜你取得獎勵",
      end: "該回合結束，關注我們的社群，掌握最新的空投資訊。",
    },

    brandstory: {
      Present: "呈獻",
      chapter01:
        "據傳，普羅米修斯是MinerPunk族群的始祖。在遠古時期，他領導著一群矮人，生活在豐饒的大地上。然而，一場大災難摧毀了他們的國土，將他們迫至絕境。",
      chapter02:
        "為了保存族群和逃避災難，普羅米修斯帶領矮人做出了一個勇敢的決定：挖掘地下，並建立起一個新的文明──MinerPunk。在地底深處，他和他的族人發現了一種強大而神秘的能源，稱為Dcoff能量。",
      chapter03:
        "Dcoff能量擁有著非凡的力量和神秘的法力。普羅米修斯和他的族人學習如何利用這種能量，透過他們的技術將其應用於各種用途，包括為整個國家提供能源。",
      chapter04:
        "但一天，普羅米修斯突然神秘地消失了。沒有人知道他的去向，也沒有人知道他的下落。儘管如此，MinerPunk的礦工們繼承了普羅米修斯的意志和技術，並繼續在地下生活，努力保護他們的國家不被外界發現。",
      rumor: "外傳",
      rumorTitle: "《公海的陰謀》",
      rumorChapter01:
        "在Minerpunk大陸，矮人專精於採集發光的能源礦石—DOCFF，維持著繁榮的生活。韋伯是一位野心勃勃的商人，他看中了矮人的挖礦技能，秘密地將他們賣到外界，迅速地成為了富翁。",
      rumorChapter02:
        "但公海組織，一個控制全球海上貿易的大組織，發現了這一切並公開譴責韋伯。公海隨後試圖獨占DOCFF，偽裝成為矮人的保護者。韋伯和矮人認識到他們的真正意圖，組建起反抗軍，成功打敗了公海。",
      rumorChapter03:
        "戰役結束後，韋伯與矮人建立了一個公平的合作協議，而公海的行為成為了一個警示，提醒人們尊重他人的權益。",
    },

    minerpunkDungeon: {
      SubscriptionConditions: "申購條件",
      MinerpunkPower: "Minerpunk的Power總和超過 50",
      DCOFFBalance: "DCOFF的餘額總和超過20000",
      CurrentDailyEarnings: "當前日收益",
      OwnershipBenefits: "持有Minerpunk Dungeon，成為礦池的主人。",
      DwarfMiningBenefit: "當矮人挖掘出DCF，礦池主人可以收取最高16%稅金。",
      MaxIssue: "最高發行量僅61張。",
      AirdropPrivilege: "可以優先提領空投獎勵及禮物。",
      QualificationStatus: "你尚未符合資格。",
      Casting: "鑄造",
      CurrentDungeonEarnings: "Minerpunk Dungeon 當前日收益",
      StockNotice: "庫存",
      PriceFluctuationWarning: "請注意 ! DCF的價格是浮動的，請自行評估風險",
      Mining: "挖礦",
      CurrentProfit: "收益狀態",
      Withdraw: "提領",
      BlockHeight: "區塊高度",
      NumberOfMines: "礦場數量",
      NumberOfMiningDwarfs: "在此挖礦的矮人數量",
      CurrentBlockMined: "當前區塊挖出的礦量",
      YourTaxRate: "您收取的稅金比例",
      CurrentBlockProfit: "當前區塊收益",
      Earnings: "收益",
      ExpectedDailyProfit: "預期日收益",
      ExpectedMonthlyProfit: "預期月收益",
      ExpectedYearlyProfit: "預期年收益",
    },
    evn: {
      sendTX: "送出交易",
      sendTXDesc: "交易已送出，正被紀錄在區塊鏈中，這需要一點時間...。",
      suceess: "交易成功",
      fail: "交易停止",
      failDesc:
        "當前的交易失敗，也許是您在過程中取消交易，或是當前的網路過於壅塞。",
    },
  },

  "zh-cn": {
    home: {
      head1: "铸造一只Minerpunk ， 让他为你挖矿",
      section1:
        "每只 MinerPunk都是独一无二的。  MinerPunk 作为 ERC-721A 存储在以太坊区块链并托管在 IPFS 。",
      noStaking:
        "更重要的是，无需授权，无需质押，持有即挖矿。资产更安全、更干净、更简单。",

      head2: "每一只MinerPunk都有算力，累积你的算力，可以挖出更多的矿",
      section2:
        "每一只矮人都赋予 1~9 分的算力，并将metadata同步在智能合约及IPFS上，你可以在具备解析metadata的平台上看见每只MinerPunk的算力，例如 X2Y2、looksrare。",
      head3: "如何挖矿? 挖什么矿?",
      section3:
        "DCOFF 是一种ERC20的代币，由ETH作为储备，提供在去中心化交易所交易。有80%的代币初始是锁在智能合约里，必须由MinerPunk们去挖掘，随着时间的推移，挖矿难度会持续提升，同样的算力能挖出的数量也会开始变少。",

      head4: "持有 MinerPunk ，成为 MinerPunk的公民。",
      section4:
        "持有MinerPunk代币时，您将享有参与MinerPunk的重大决策权和公民福利。您的提议可纳入投票程序和决议，同时您还可以获得空投代币奖励。当然，您也有责任监控MinerPunk以防范诈骗活动。让我们一起共同治理这个平台，使其更加和平、更强大，更像是一个能保护您的国家。",
      head5: "一种结合挖矿、Defi、代币经济、DAO为一体的NFT",
      section5: "GET ME IF YOU CAN.",
      brandstory: "奇幻故事",
    },
    Miner: {
      DcoffPrice: "Dcoff 价格",
      MineStatus: "挖矿状态",
      Claim: "领取",
    },
    Mint: {
      title: "铸造",
      MaxSupply: "发行总量",
      Inventory: "库存",
      Open: "开放",
      Sold: "已售",
      Insufficientbalance: "余额不足",
      SoldOut: "售完",
      SecurityCheck: "正在安全性检查，请稍后。",
      FeatureA: "持有即挖矿，不需授权。",
      FeatureB: "每只都是独一无二。",
      FeatureC: "图片都托管至IPFS。",
      FeatureD: "以ERC721A做为标准。",
    },
    Mine: {
      title: "挖矿",
      MinePower: "挖矿算力",
      NFTBalance: "NFT 余额",
      EnterDungeon: "进入地下城",
      dugupintro: "当前区块挖出的代币总和",
      fetchDifficulty: "正在计算当前挖矿难度......",
      ExpectedDailyReturn: "预期日收益",
      ExpectedMonthlyReturn: "预期月收益",
      ExpectedAnnualReturn: "预期年收益",
      Difficulty: "挖矿难度",
    },
    Token: {
      title: "金矿",
      Balance: "金矿余额",
      price: "价格",
      MaxSupply: "最高发行量",
      TotalSupply: "当前发行总量",
      CirculatingSupply: "流动在外数量",
      Locked: "锁在合约数量",
      Burned: "已烧毁数量",
      CONTRACTADDRESS: "合约地址",
    },

    airdrop: {
      airdrop: "空投",
      NoAirdrop: "对不起 ！你没有任何空投。",
      Claim: "提领",
      Condition01: "拥有一只MinerPunk的NFT",
      Condition02: "被邀请进入白名单。",
    },
    badge: {
      title: "徽章",
      tab: {
        task: "条件",
        directions: "说明",
      },
      messages: {
        1: "你发现了一颗闪闪发亮的矿石，这是个难得的机会！快拿起它，也许会带来好运！",
        2: "当你的DCF数量达到5000枚时，你将获得DCF守护者的称号。这是你努力的成果，也是对Minerpunk世界的贡献。",
        3: "除了挖矿，你还有一个不可思议的技能：魔法！你可以破解智能合约，打开时间锁。你可以到社群寻找线索，或者直接到这里开始破解。",
        4: "当你的算力达到20，代表你已经在Minerpunk世界里做出了巨大贡献。你的矮人们非常出色！",
        5: "恭喜你！你已经获得前四个徽章，成为我们的高级会员。你将获得更多福利和礼物，同时也将享有无上的光荣！",
      },
      completeinfo: {
        1: "成功取得",
        2: "5000 / 5000 DCF",
        3: "成功破解",
        4: "20/20 Power",
        5: "5/5 Badges",
      },
      badge01title: "Dcoff",
      badge01description:
        "在Minerpunk矮人的世界里，有一种珍贵的矿石叫做Dcoff。这种矿石由以太坊组成，是Minerpunk矮人社会的主要能源来源。Minerpunk矮人依赖Dcoff矿石维持日常生活，因为它提供了所需的能量来驱动城市和机器，并成为他们工业和技术的基础。每当在矿山中发现新的矿脉，Minerpunk人民就会聚集在一起开始开采这种宝贵的资源。",
      badge02title: "DCOFF 守护者",
      badge02description:
        "DCOFF 是一种稀有且重要的能源来源，被视为未来文明发展的关键因素。在Minerpunk中，许多矮人争夺和保护它以建立自己的文明。",
      badge03title: "时间胶囊",
      badge03description:
        "时间胶囊是一个神秘的盒子，被时间魔法锁住，只有真正的天才才能破解它的密码。许多人追寻这个传说中的宝藏，但很少有人能解决它的难题。在Minerpunk的世界中，它被认为是最具挑战性的任务之一，并且深受尊敬。",
      badge04title: "文明推进者",
      badge04description:
        "你的矮人是采矿和资源管理的专家，他们拥有独特的技能和能力来开采更多的DCOFF矿石，为Minerpunk文明的发展做出贡献。他们的劳动和努力对社会的进步至关重要，因为DCOFF是未来文明的关键能源来源，你的矮人的贡献将被记录在历史中。",
      badge05title: "Minerpunk俱乐部",
      badge05description:
        "在Minerpunk社群中取得最高的贡献是一个难以置信的成就，你的努力和付出为文明的发展做出了优秀的贡献。你不仅是一个优秀的成员，更是潮流的引领者。 你的成功不仅是你自己的，也是 Minerpunk 社区所有成员的集体荣誉。",
    },
    cashbox: {
      round: "回合数",
      winner: "获胜者",
      rules:
        "当倒数计时器归零时，按下提领键便可抢夺存放在合约里的USDT。每次只有一名参加者能抽中，当参加者提领成功时，开放时间就会往后延长1小时，直到合约里的USDT归零，竞赛便结束。",
      warning:
        "此次活动属于竞赛性质，无论您有无获奖，gas fee都必须自己承担，若有任何疑虑请勿参与。",
      Condition: "参与的条件",
      unconditional: "无须任何条件",
      Uptostandard01: "拥有Minerpunk Badges #001 的NFT。",
      Uptostandard02: "拥有Minerpunk 的NFT。",
      Uptostandard03: "拥有Minerpunk Badges #005 的NFT。",
      notuptostandard01: "必须先拥有Minerpunk Badges #001 的NFT。",
      notuptostandard02: "必须先拥有Minerpunk 的NFT。",
      notuptostandard03: "必须先拥有Minerpunk Badges #005 的NFT。",
      claim: "提领",
      claimed: "恭喜你取得奖励",
      end: "该回合结束，关注我们的社群，掌握最新的空投资讯。",
    },
    brandstory: {
      Present: "呈现",
      chapter01:
        "据传，普罗米修斯是MinerPunk族群的始祖。在远古时期，他领导着一群矮人，生活在丰饶的大地上。然而，一场大灾难摧毁了他们的国土，将他们迫至绝境。 ",
      chapter02:
        "为了保存族群和逃避灾难，普罗米修斯带领矮人做出了一个勇敢的决定：挖掘地下，并建立起一个新的文明──MinerPunk。在地底深处，他和他的族人发现了一种强大而神秘的能源，称为Dcoff能量。",
      chapter03:
        "Dcoff能量拥有着非凡的力量和神秘的法力。普罗米修斯和他的族人学习如何利用这种能量，透过他们的技术将其应用于各种用途，包括为整个国家提供能源。",
      chapter04:
        "但一天，普罗米修斯突然神秘地消失了。没有人知道他的去向，也没有人知道他的下落。尽管如此，MinerPunk的矿工们继承了普罗米修斯的意志和技术，并继续在地下生活，努力保护他们的国家不被外界发现。",
      rumor: "外传",
      rumorTitle: "《公海的阴谋》",
      rumorChapter01:
        "在Minerpunk大陆，矮人专精于采集发光的能源矿石—DOCFF，维持着繁荣的生活。韦伯是一位野心勃勃的商人，他看中了矮人的挖矿技能，秘密地将他们卖到外界，迅速地成为了富翁。",
      rumorChapter02:
        "但公海组织，一个控制全球海上贸易的大组织，发现了这一切并公开谴责韦伯。公海随后试图独占DOCFF，伪装成为矮人的保护者。韦伯和矮人认识到他们的真正意图，组建起反抗军，成功打败了公海。",
      rumorChapter03:
        "战役结束后，韦伯与矮人建立了一个公平的合作协议，而公海的行为成为了一个警示，提醒人们尊重他人的权益。",
    },
    minerpunkDungeon: {
      SubscriptionConditions: "申购条件",
      MinerpunkPower: "Minerpunk的Power总和超过 50",
      DCOFFBalance: "DCOFF的余额总和超过20000",
      CurrentDailyEarnings: "当前日收益",
      OwnershipBenefits: "持有Minerpunk Dungeon，成为矿池的主人。",
      DwarfMiningBenefit: "当矮人挖掘出DCF，矿池主人可以收取最高16%税金。",
      MaxIssue: "最高发行量仅61张。",
      AirdropPrivilege: "可以优先提领空投奖励及礼物。",
      QualificationStatus: "你尚未符合资格。",
      Casting: "铸造",
      CurrentDungeonEarnings: "Minerpunk Dungeon 当前日收益",
      StockNotice: "库存",
      PriceFluctuationWarning: "请注意 ! DCF的价格是浮动的，请自行评估风险",
      Mining: "挖矿",
      CurrentProfit: "收益状态",
      Withdraw: "提领",
      BlockHeight: "区块高度",
      NumberOfMines: "矿场数量",
      NumberOfMiningDwarfs: "在此挖矿的矮人数量",
      CurrentBlockMined: "当前区块挖出的矿量",
      YourTaxRate: "您收取的税金比例",
      CurrentBlockProfit: "当前区块收益",
      Earnings: "收益",
      ExpectedDailyProfit: "预期日收益",
      ExpectedMonthlyProfit: "预期月收益",
      ExpectedYearlyProfit: "预期年收益",
    },
    evn: {
      sendTX: "送出交易",
      sendTXDesc: "交易已送出，正被纪录在区块链中，这需要一点时间...。",
      suceess: "交易成功",
      fail: "交易停止",
      failDesc:
        "当前的交易失败，也许是您在过程中取消交易，或是当前的网路过于壅塞。",
    },
  },

  en: {
    home: {
      head1: "Mint a MinerPunk  and mine Dcoff for you",
      section1:
        "Each MinerPunk is unique. MinerPunk is stored as an ERC-721A on the Ethereum blockchain and hosted on IPFS.",
      noStaking:
        "no authorization or staking is required. Holding is equivalent to mining. Assets are safer, cleaner, and simpler.",
      head2:
        "Each MinerPunk has power, accumulate your power, you can dig out more mines.",
      section2:
        "Each dwarf is assigned a computing power score between 1 and 9, and the metadata is synchronized on both the smart contract and IPFS. You can see the computing power of each MinerPunk on platforms that can parse metadata, such as X2Y2 and looksrare.",
      head3: "How to mine?  What to mine",
      section3:
        "DCOFF is an ERC20 token, backed by ETH, and traded on decentralized exchanges. 80% of the tokens are initially locked in the smart contract and must be mined by  MinerPunk. Over time, the difficulty of mining will continue to increase, and the amount that can be dug out with the same power will begin to decrease.",

      head4: "Hold a MinerPunk and become a MinerPunk citizen.",
      section4:
        "Hold MinerPunk for decision-making power, benefits, and rewards. Monitor fraud. Let's govern and protect together.",
      head5: "An NFT that combines Mining, Defi, Tokenomics, and DAO.",
      section5: "GET ME IF YOU CAN.",
      brandstory: "Fantasy Story",
    },
    Miner: {
      DcoffPrice: "Dcoff Price",
      MineStatus: "Mine Status",
      Claim: "Claim",
    },
    Mint: {
      title: "Mint",
      MaxSupply: "MaxSupply",
      Inventory: "Inventory",
      Open: "Open",
      Sold: "Sold",
      Insufficientbalance: "Insufficient balance",
      SoldOut: "Sold Out",
      SecurityCheck: "Checking security",
      FeatureA: "Hold and mine without authorization.",
      FeatureB: "Each one is unique",
      FeatureC: "All images are hosted on IPFS",
      FeatureD: "Using ERC721A as the standard.",
    },
    Mine: {
      title: "Mine",
      MinePower: "Mine Power",
      NFTBalance: "NFT Balance ",
      EnterDungeon: "Enter Dungeon",
      dugupintro: "The sum of tokens mined in the current block",
      fetchDifficulty: "Calculating the current mining difficulty...",
      ExpectedDailyReturn: "Expected Daily Return",
      ExpectedMonthlyReturn: "Expected Monthly Return",
      ExpectedAnnualReturn: "Expected Annual Return",
      Difficulty: "Difficulty",
    },
    Token: {
      title: "Token",
      Balance: "Balance",
      price: "Price",
      MaxSupply: "Max Supply",
      TotalSupply: "Total Supply",
      CirculatingSupply: "Circulating Supply",
      Locked: "Locked",
      Burned: "Burned",
      CONTRACTADDRESS: "CONTRACT ADDRESS",
    },
    airdrop: {
      airdrop: "Airdrop",
      NoAirdrop: "Sorry! You don't have any airdrops.",
      Claim: "Claim",
      Condition01: "Owning an NFT of MinerPunk.",
      Condition02: "Invited to whitelist.",
    },
    badge: {
      title: "Badges",
      tab: {
        task: "Condition",
        directions: "Description",
      },
      messages: {
        1: "You have found a shiny ore, this is a rare opportunity! Pick it up, it may bring good luck!",
        2: "When your DCF number reaches 5000, you will be awarded the title of DCF Guardian. This is the result of your hard work and a contribution to the Minerpunk world.",
        3: "In addition to mining, you possess an incredible skill: magic! You can crack smart contracts and unlock time locks. You can seek clues from the community or start cracking right here.",
        4: "When your computing power reaches 20, it means you have made a great contribution in the Minerpunk world. Your dwarves are very good!",
        5: "Congratulations! You have obtained the first four badges and become our senior member. You will receive more benefits and gifts, and you will also enjoy supreme glory!",
      },
      completeinfo: {
        1: "Successfully Obtained",
        2: "5000 / 5000 DCF",
        3: "Successfully Cracked",
        4: "20/20 Power",
        5: "5/5 Badges",
      },
      badge01title: "Dcoff",
      badge01description:
        "In the world of the Minerpunk dwarves, there is a precious ore called Dcoff. This ore is composed of Ethereum and serves as the primary source of energy for the Minerpunk dwarf society. The Minerpunk dwarves depend on Dcoff ore for their daily lives, as it provides the energy needed to power their cities and machines, and serves as the foundation of their industry and technology. Whenever a new vein of ore is discovered in the mines, the Minerpunk people gather together to begin the difficult work of mining this valuable resource.",
      badge02title: "DCOFF Guardian",
      badge02description:
        "DCOFF is a rare and important energy source that is considered a key factor in the development of future civilizations. In the Minerpunk, many dwarf compete and protect it in order to build their own civilizations.",
      badge03title: "Time Capsule",
      badge03description:
        "The Time Capsule is a mysterious box locked by time magic, and only true geniuses can crack its code. Many people pursue the legendary treasure, but few can solve its puzzle. It is considered to be one of the most challenging tasks and is highly respected in the world of Minerpunk.",
      badge04title: "Civilizational Promoter",
      badge04description:
        "Your dwarves are experts in mining and resource management, and they have unique skills and abilities to excavate more DCOFF and contribute to the development of Minerpunk civilization. Their labor and efforts are crucial for the society's progress, as DCOFF is a key energy source for future civilizations, and the contributions of your dwarves will be remembered in history.",
      badge05title: "Minerpunk Club",
      badge05description:
        "Achieving the highest contribution in the Minerpunk community is an incredible accomplishment, and your hard work and efforts have made outstanding contributions to the development of civilization. You are not only an excellent member but also a trendsetter and leader. Your success is not only your own but also the collective honor of all members of the Minerpunk community.",
    },

    cashbox: {
      round: "Round",
      winner: "Winner",
      rules:
        "When the countdown timer hits zero, you can press the claim button to attempt to seize the USDT stored in the contract. There can only be one successful claimant each time. Once a participant successfully makes a claim, the opening time will be extended by one hour. This will continue until the USDT in the contract is depleted, marking the end of the competition.",
      warning:
        "This event is competitive in nature. Regardless of whether you win a prize or not, you will have to bear the gas fee. If you have any concerns, please do not participate.",
      Condition: "Participation Condition",
      unconditional: "No conditions required",
      Uptostandard01: "Possess the Minerpunk Badges #001 NFT.",
      Uptostandard02: "Possess an NFT from Minerpunk.",
      Uptostandard03: "Possess the Minerpunk Badges #005 NFT.",
      notuptostandard01:
        "You must first possess the Minerpunk Badges #001 NFT.",
      notuptostandard02: "You must first possess an NFT from Minerpunk.",
      notuptostandard03:
        "You must first possess the Minerpunk Badges #005 NFT.",
      claim: "Claim",
      claimed: "congratulations on your reward",
      end: "This round is over, follow our community to stay updated on the latest airdrop information.",
    },

    brandstory: {
      Present: "Presents",
      chapter01:
        "Legend has it that Prometheus was the ancestor of the MinerPunk tribe. In ancient times, he led a group of dwarves, living on the fertile land. However, a great catastrophe destroyed their homeland, driving them to the brink of despair.",
      chapter02:
        "To preserve their tribe and escape the disaster, Prometheus made a brave decision to lead the dwarves to dig underground and establish a new civilization—MinerPunk. Deep beneath the earth, he and his people discovered a powerful and mysterious energy source called Dcoff energy.",
      chapter03:
        "Dcoff energy possessed remarkable strength and mystical powers. Prometheus and his tribe learned to harness this energy, applying it through their technology for various purposes, including powering their entire nation.",
      chapter04:
        "But one day, Prometheus mysteriously vanished. No one knew where he went, and his whereabouts remained a mystery. Nevertheless, the miners of MinerPunk carried on Prometheus's will and techniques, continuing to live underground, working hard to keep their nation hidden from the outside world.",
      rumor: "Rumor",
      rumorTitle: "Conspiracy of the High Seas",
      rumorChapter01:
        "In the continent of Minerpunk, dwarves specialize in mining the luminous energy ore—DOCFF, maintaining a prosperous life. Weber is an ambitious merchant who saw the mining skills of the dwarves and secretly sold them to the outside world, quickly becoming wealthy.",
      rumorChapter02:
        "But the High Seas Organization, a major organization that controls global maritime trade, discovered all this and publicly denounced Weber. The High Seas then tried to monopolize DOCFF, disguising themselves as protectors of the dwarves. Weber and the dwarves realized their true intentions and formed a resistance, successfully defeating the High Seas.",
      rumorChapter03:
        "After the battle, Weber and the dwarves established a fair cooperation agreement, and the actions of the High Seas became a warning, reminding people to respect the rights of others.",
    },
    minerpunkDungeon: {
      SubscriptionConditions: "Subscription Conditions",
      MinerpunkPower: "Minerpunk Power Total exceeds 50",
      DCOFFBalance: "DCOFF Balance Total exceeds 20,000",
      CurrentDailyEarnings: "Current Daily Earnings",
      OwnershipBenefits:
        "Owning Minerpunk Dungeon, become the owner of the mining pool.",
      DwarfMiningBenefit:
        "When dwarfs mine DCF, the pool owner can collect up to a 16% tax.",
      MaxIssue: "Maximum issuance of only 61 cards.",
      AirdropPrivilege: "Priority in claiming airdrop rewards and gifts.",
      QualificationStatus: "You do not currently qualify.",
      Casting: "Mint",
      CurrentDungeonEarnings: "Current Daily Earnings of Minerpunk Dungeon",
      StockNotice: "In Stock",
      PriceFluctuationWarning:
        "Please note! The price of DCF is volatile, assess the risk yourself",
      Mining: "Mining",
      CurrentProfit: "Profit Status",
      Withdraw: "Withdraw",
      BlockHeight: "Block Height",
      NumberOfMines: "Number of Mines",
      NumberOfMiningDwarfs: "Number of Dwarfs Mining Here",
      CurrentBlockMined: "Amount Mined in Current Block",
      YourTaxRate: "Tax Rate You Collect",
      CurrentBlockProfit: "Current Block Profit",
      Earnings: "Income",
      ExpectedDailyProfit: "Expected Daily Profit",
      ExpectedMonthlyProfit: "Expected Monthly Profit",
      ExpectedYearlyProfit: "Expected Yearly Profit",
    },

    evn: {
      sendTX: "send transaction",
      sendTXDesc:
        "The transaction has been sent and is being recorded in the blockchain, it will take a while....",
      success: "The transaction was successful",
      fail: "Transaction stopped",
      failDesc:
        "The current transaction failed, maybe you canceled the transaction in the process, or the current network is too congested.",
    },
  },

  ja: {
    home: {
      head1: "Minerpunkを鋳造し、彼らに採掘させましょう",
      section1:
        "Minerpunkはすべてユニークです。MinerpunkはERC-721AとしてEthereumブロックチェーンに保存され、IPFSでホスティングされます。",
      noStaking:
        "重要なのは、許可もステーキングも必要ありません。所有するだけで採掘できます。より安全で、よりクリーンで、より簡単なアセットです。",
      head2:
        "すべてのMinerpunkには算力があり、累積された算力でより多くの鉱石を採掘できます。",
      section2:
        "各ドワーフには1から9までの計算力が割り当てられ、メタデータはスマートコントラクトとIPFSの両方で同期されます。X2Y2やlooksrareのようなメタデータを解析できるプラットフォームで、各MinerPunkの計算力を確認することができます。",
      head3: "採掘方法と採掘対象",
      section3:
        "DCOFFはERC20トークンで、ETHを準備に使い、分散型取引所で取引できます。トークンの80％はスマートコントラクトにロックされ、Minerpunkによって掘り起こされる必要があります。時間の経過とともに、採掘難易度は持続的に上昇し、同じ算力でも採掘できる量が減少します。",
      head4: "Minerpunkを所有すると、Minerpunkの市民となります。",
      section4:
        "Minerpunkトークンを所有すると、Minerpunkに関する重要な意思決定権と市民の特典を享受できます。あなたの提案は投票手順と決議に組み込まれることがあります。また、エアドロップトークンの報酬も受け取れます。もちろん、詐欺行為に備えてMinerpunkを監視する責任もあります。一緒にこのプラットフォームを共同運営し、より平和で、より強力で、よりあなたの国を守る力を持つものにしましょう。",
      head5: "採掘、Defi、トークン経済、DAOを統合したNFT",
      section5: "GET ME IF YOU CAN。",
      brandstory: "ファンタジーストーリー",
    },
    Miner: {
      DcoffPrice: "DCOFF価格",
      MineStatus: "採掘状況",
      Claim: "受け取り",
    },
    Mint: {
      title: "鋳造",
      MaxSupply: "発行総量",
      Inventory: "在庫",
      Open: "オープン",
      Sold: "販売済",
      Insufficientbalance: "残高不足",
      SoldOut: "完売",
      SecurityCheck: "セキュリティチェック中です。しばらくお待ちください。",
      FeatureA: "所有するだけで採掘できます。許可不要。",
      FeatureB: "すべてがユニークです。",
      FeatureC: "画像はIPFSにホストされます。",
      FeatureD: "ERC721Aが標準として使用されています。",
    },
    Mine: {
      title: "採掘",
      MinePower: "採掘算力",
      NFTBalance: "NFT残高",
      EnterDungeon: "ダンジョンに入る",
      dugupintro: "現在のブロックで掘り起こされたトークンの合計",
      fetchDifficulty: "現在の採掘難易度を計算しています...",
      ExpectedDailyReturn: "予想日間リターン",
      ExpectedMonthlyReturn: "予想月間リターン",
      ExpectedAnnualReturn: "予想年間リターン",
      Difficulty: "採掘難易度",
    },
    Token: {
      title: "金鉱",
      Balance: "金鉱残高",
      price: "価格",
      MaxSupply: "最高発行量",
      TotalSupply: "現在の発行総量",
      CirculatingSupply: "流通している数量",
      Locked: "契約にロックされた数量",
      Burned: "燃やされた数量",
      CONTRACTADDRESS: "契約アドレス",
    },
    airdrop: {
      airdrop: "エアドロップ",
      NoAirdrop: "申し訳ありません！エアドロップはありません。",
      Claim: "受け取り",
      Condition01: "MinerpunkのNFTを所有していること",
      Condition02: "ホワイトリストに招待されたこと",
    },
    badge: {
      title: "バッジ",
      tab: {
        task: "条件",
        directions: "説明",
      },
      messages: {
        1: "輝く鉱石を見つけました！これは貴重なチャンスです！さっと持ち上げて、幸運をもたらすかもしれません！",
        2: "あなたのDCF残高が5000枚に達したとき、あなたはDCFガーディアンの称号を得ます。これはあなたの努力とMinerpunk世界への貢献です。",
        3: "採掘以外にも、あなたは不思議な力を持っています：魔法！スマートコントラクトを解読し、時間ロックを解除できます。コミュニティで手がかりを探したり、直接ここから解読を始めることができます。",
        4: "あなたの算力が20に達すると、あなたはMinerpunk世界に大きな貢献をしたことを意味します。あなたの矮人たちは非常に優れています！",
        5: "おめでとうございます！前四つのバッジを獲得し、私たちの上級メンバーになりました。もっと多くの特典と贈り物を得るだけでなく、最高の名誉を享受できます！",
      },
      completeinfo: {
        1: "達成",
        2: "5000 / 5000 DCF",
        3: "達成",
        4: "20 / 20 算力",
        5: "5 / 5 バッジ",
      },
      badge01title: "DCOFF",
      badge01description:
        "Minerpunkの世界では、DCOFFという貴重な鉱石が存在します。この鉱石はEthereumで構成され、Minerpunkの社会の主要なエネルギー源です。Minerpunkの人々は日常生活を維持するためにDCOFF鉱石を必要とし、それは都市や機械を駆動する必要なエネルギーを提供し、産業と技術の基盤となります。鉱山で新しい鉱脈が発見されるたびに、Minerpunkの人々は貴重な資源を採掘するために集まります。",
      badge02title: "DCOFFガーディアン",
      badge02description:
        "DCOFFは稀少かつ重要なエネルギー源であり、未来の文明の発展における鍵と見なされています。Minerpunkでは、多くの矮人がそれを獲得し、保護するために争い、自分たちの文明を築いています。",
      badge03title: "タイムカプセル",
      badge03description:
        "タイムカプセルは神秘的なボックスであり、時間魔法によってロックされており、真の天才しかその謎を解くことができません。多くの人がこの伝説の宝物を追い求めていますが、その難問を解くことができるのはわずかです。Minerpunkの世界では、それが最も難解なタスクの1つであり、非常に尊敬されています。",
      badge04title: "文明の進化者",
      badge04description:
        "あなたの矮人は鉱山や資源管理のエキスパートであり、より多くのDCOFF鉱石を採掘し、Minerpunk文明の発展に貢献しています。彼らの労働と努力は社会の進歩に不可欠であり、DCOFFは未来のエネルギー源として重要です。あなたの矮人の貢献は歴史に記録されます。",
      badge05title: "Minerpunkクラブ",
      badge05description:
        "Minerpunkコミュニティで最も大きな貢献をすることは信じられない成果です。あなたの努力と献身によって文明の発展に貢献しています。あなたは優れたメンバーになるだけでなく、トレンドをリードする存在でもあります。あなたの成功はあなた自身だけでなく、Minerpunkコミュニティ全員の共同の誇りです。",
    },
    cashbox: {
      round: "ラウンド",
      winner: "勝者",
      rules:
        "カウントダウンタイマーがゼロになったとき、引き出しボタンを押して契約内に保存されているUSDTを獲得しようとすることができます。一度に成功する請求者は1人だけです。参加者が成功して請求すると、開放時間が1時間延長されます。これは契約内のUSDTがなくなるまで続き、それがコンテストの終了を意味します。",
      warning:
        "このイベントは競争性のあるものであり、賞を獲得しようがしまいが、ガス料は自己負担となります。何か疑問がある場合は、参加を控えてください。",
      Condition: "参加条件",
      unconditional: "条件なし",
      Uptostandard01: "Minerpunk Badges #001のNFTを所有しています。",
      Uptostandard02: "MinerpunkのNFTを所有しています。",
      Uptostandard03: "Minerpunk Badges #005のNFTを所有しています。",
      notuptostandard01:
        "まずMinerpunk Badges #001のNFTを所有する必要があります。",
      notuptostandard02: "まずMinerpunkのNFTを所有する必要があります。",
      notuptostandard03:
        "まずMinerpunk Badges #005のNFTを所有する必要があります。",
      claim: "請求",
      claimed: "ご褒美おめでとうございます",
      end: "このラウンドは終了しました。最新のエアドロップ情報を入手するために、私たちのコミュニティにご参加ください。",
    },

    brandstory: {
      Present: "献呈",
      chapter01:
        "伝説によると、プロメテウスはMinerPunk部族の祖先だったと言われています。古代、彼は一群のドワーフを率いて、豊かな大地で生活していました。しかし、大きな災害が彼らの故郷を破壊し、彼らを絶望の淵に追いやりました。",
      chapter02:
        "部族を保護し、災害から逃れるため、プロメテウスは地下を掘り、新しい文明、すなわちMinerPunkを築く勇気ある決断をしました。地下の深いところで、彼と彼の人々はDcoffエネルギーと呼ばれる強力で神秘的なエネルギー源を発見しました。",
      chapter03:
        "Dcoffエネルギーは卓越した力と神秘的な力を持っていました。プロメテウスと彼の部族はこのエネルギーを利用する方法を学び、それを技術を通してさまざまな目的に適用し、彼らの全国家に電力を供給しました。",
      chapter04:
        "しかし、ある日、プロメテウスは突然神秘的に姿を消しました。彼がどこへ行ったのか、彼の居場所は謎のままでした。それにもかかわらず、MinerPunkの鉱山労働者たちはプロメテウスの意志と技術を受け継ぎ、地下での生活を続け、彼らの国が外界から見つけられないように努力し続けました。",
      rumor: "噂",
      rumorTitle: "公海の陰謀",
      rumorChapter01:
        "Minerpunk大陸にて、ドワーフは光るエネルギー鉱石—DOCFFの採掘を得意とし、繁栄する生活を維持しています。ウェーバーは、ドワーフの採掘スキルを見て、秘密に外界に彼らを売り、迅速に富裕になりました。",
      rumorChapter02:
        "しかし、公海組織、全世界の海上貿易を制御する大きな組織は、これを発見し、ウェーバーを公然と非難しました。公海はその後、DOCFFを独占しようとし、ドワーフの保護者として偽装しました。ウェーバーとドワーフは彼らの真の意図を理解し、抵抗を結成し、公海を成功裏に打ち負かしました。",
      rumorChapter03:
        "戦いの後、ウェーバーとドワーフは公平な協力協定を結びました。公海の行動は、他人の権利を尊重することを人々に思い出させる警告となりました。",
    },

    minerpunkDungeon: {
      SubscriptionConditions: "申し込み条件",
      MinerpunkPower: "MinerpunkのPowerの合計が50を超える",
      DCOFFBalance: "DCOFFの残高の合計が20,000を超える",
      CurrentDailyEarnings: "現在の日次収益",
      OwnershipBenefits: "Minerpunk Dungeonを所有し、鉱山のオーナーになる。",
      DwarfMiningBenefit:
        "ドワーフがDCFを採掘すると、鉱山のオーナーは最大16%の税金を収集できる。",
      MaxIssue: "最大発行枚数は61枚のみ。",
      AirdropPrivilege:
        "エアドロップの報酬とプレゼントを優先的に受け取ることができる。",
      QualificationStatus: "現在、資格がありません。",
      Casting: "鋳造",
      CurrentDungeonEarnings: "Minerpunk Dungeonの現在の日次収益",
      StockNotice: "在庫",
      PriceFluctuationWarning:
        "注意！DCFの価格は変動するため、リスクを自分で評価してください",
      Mining: "採掘",
      CurrentProfit: "収益状態",
      Withdraw: "引き出し",
      BlockHeight: "ブロック高",
      NumberOfMines: "鉱山の数",
      NumberOfMiningDwarfs: "ここで採掘しているドワーフの数",
      CurrentBlockMined: "現在のブロックで採掘された鉱石の量",
      YourTaxRate: "あなたの税率",
      CurrentBlockProfit: "現在のブロックの利益",
      Earnings: "収益",
      ExpectedDailyProfit: "予想日次利益",
      ExpectedMonthlyProfit: "予想月次利益",
      ExpectedYearlyProfit: "予想年間利益",
    },

    evn: {
      sendTX: "トランザクションを送信",
      sendTXDesc:
        "トランザクションが送信され、ブロックチェーンに記録されています。しばらくお待ちください...",
      suceess: "トランザクションが成功しました",
      fail: "トランザクションが中止されました",
      failDesc:
        "現在のトランザクションは失敗しました。途中でキャンセルした可能性がありますし、ネットワークが混雑しているかもしれません。",
    },
  },
};

export function getlangselect(i) {
  return langselect[i];
}
