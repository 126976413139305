import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import { getSmartContract } from "../SmartContract";
import { getlangselect } from "../Components/lang/langselect";
import { BlockStatus, MDBlockStatus } from "./blockstatus";
import { Web3Button } from "@web3modal/react";
import { Socialmedia, Media } from "./Socialmedia";
import { GetDCFpriceETH, GetDCFpriceUSD } from "./pricing";
import { LinkPort } from "./LinkPort";
import { _Percent } from "./Tool";
import Card from "react-animated-3d-card";
import ReactApexChart from "react-apexcharts";
import CountUp from "react-countup";
import Dcofficon from "../img/myDcoff.png";
import USDicon from "../img/DollarUSD.png";
import ETHicon from "../img/ETH.png";
import mainLogo from "../img/mainLogo.png";
import LogoWord from "../img/DcofferLogoWord.png";
import mainLogoERC1155 from "../img/mainLogoERC1155.png";
import energy from "../img/energy-icon.png";
import mining from "../img/DcoffMine.gif";
import stopMine from "../img/stopMine.png";
import myDcoff from "../img/myDcoff.png";
import _CirculatingSupply from "../img/icon/CirculatingSupply.png";
import _locked from "../img/icon/locked.png";
import _burn from "../img/icon/burn.png";
import X2Y2 from "../img/icon/X2Y2.png";
import looksrare from "../img/icon/looksrare.png";
import badge01 from "../img/badge/badge01.png";
import badge02 from "../img/badge/badge02.png";
import badge03 from "../img/badge/badge03.png";
import badge04 from "../img/badge/badge04.png";
import badge05 from "../img/badge/badge05.png";
import lock_badge01 from "../img/badge/lock_badge01.png";
import lock_badge02 from "../img/badge/lock_badge02.png";
import lock_badge03 from "../img/badge/lock_badge03.png";
import lock_badge04 from "../img/badge/lock_badge04.png";
import lock_badge05 from "../img/badge/lock_badge05.png";
import {
  useContractRead,
  useAccount,
  useBlockNumber,
  useBalance,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

import {
  ChakraProvider,
  useControllableState,
  Button,
  Text,
  Stat,
  StatHelpText,
  StatArrow,
  StatGroup,
  useDisclosure,
  SlideFade,
  useToast,
  Center,
  Icon,
  Box,
  Divider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Skeleton,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";

const Smartcontract = getSmartContract();

export function Addr() {
  const { address } = useAccount();
  return address;
}

function Balance() {
  const { address } = useAccount();
  const { data } = useBalance({
    address: address,
  });

  return Number(data?.formatted).toFixed(4);
}

function BlockNum() {
  const [blockNum, setBlockNum] = useState(0);
  const { data, isError, isLoading } = useBlockNumber();

  useEffect(() => {
    if (!isLoading && !isError && blockNum === 0) {
      setBlockNum(data);
    }
  }, [isLoading, isError, blockNum, data]);

  let Data = Number(data);
  if (isNaN(Data)) return 0;
  return Data;
}

export function DocffBalnance() {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.ERC20Dcoff.address,
    abi: Smartcontract.ERC20Dcoff.abi,
    functionName: "balanceOf",
    args: [address],
  });

  let Data = Number(data);
  Data = Number(Data / 1e18).toFixed(4);
  if (isNaN(Data) || Data == 0) return "00.000";

  return Data;
}

function DocffBalnanceETH() {
  let balance = DocffBalnance();
  balance = balance * GetDCFpriceETH();

  return balance.toFixed(3);
}

function DocffBalnanceUSD() {
  let balance = DocffBalnance();
  balance = balance * GetDCFpriceUSD();
  return balance.toFixed(3);
}

function DcoffMineBalnance() {
  const { data } = useContractRead({
    address: Smartcontract.ERC20Dcoff.address,
    abi: Smartcontract.ERC20Dcoff.abi,
    functionName: "balanceOf",
    args: ["0xbF448C034649FC79E09afF3dc3a546c74c00CEBc"],
  });

  let Data = Number(data);
  Data = (Data / 1e18).toFixed(4);
  if (isNaN(Data)) return 0;

  return Data;
}

function DcoffMDMineBalnance() {
  const { data } = useContractRead({
    address: Smartcontract.ERC20Dcoff.address,
    abi: Smartcontract.ERC20Dcoff.abi,
    functionName: "balanceOf",
    args: ["0xA11277ACCf38533382A2ca12A4A787147dB6ACB4"],
  });

  let Data = Number(data);
  Data = (Data / 1e18).toFixed(4);
  if (isNaN(Data)) return 0;

  return Data;
}

function TotalSupply() {
  const { data } = useContractRead({
    address: Smartcontract.ERC20Dcoff.address,
    abi: Smartcontract.ERC20Dcoff.abi,
    functionName: "totalSupply",
  });

  let Data = Number(data);
  Data = (Data / 1e18).toFixed(4);
  if (isNaN(Data)) return 0;

  return Data;
}

function Burned() {
  let _burned = Number(100000000 - TotalSupply());
  _burned = _burned.toFixed(4);
  if (isNaN(_burned) == true) return 0;
  return _burned;
}

function CirculatingSupply() {
  let _CirculatingSupply =
    TotalSupply() - DcoffMineBalnance() - DcoffMDMineBalnance();
  if (isNaN(_CirculatingSupply) == true) return 0;
  return _CirculatingSupply.toFixed(4);
}

export function NFTPrice() {
  const { data } = useContractRead({
    address: Smartcontract.minerpunkNFT.address,
    abi: Smartcontract.minerpunkNFT.abi,
    functionName: "ViewInfo",
    args: [0, 1],
  });

  let Data = Number(data);
  Data = Data / 1e18;
  if (isNaN(Data)) return 0;

  return Data;
}

function Mintboard() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);
  const { address } = useAccount();
  let [value, setValue] = useControllableState({ defaultValue: 1 });

  let balance = Number(Balance());
  let inventory = NFTOpen() - NFTSold();
  const total = Number((value * NFTPrice()).toFixed(2));
  const payment = (total * 1e18).toString();

  const toast = useToast();

  const TXInfo = (_title, _description, _status) => {
    toast({
      position: "top",
      title: _title,
      description: _description,
      status: _status,
      duration: 9000,
      isClosable: true,
    });
  };

  function sub() {
    if (value <= 1) {
      setValue((value = 1));
    } else {
      setValue(value - 1);
    }
  }

  function add() {
    if (value >= inventory) {
      setValue((value = inventory));
    } else {
      setValue(value + 1);
    }
  }

  const { config, error } = usePrepareContractWrite({
    address: Smartcontract.minerpunkNFT.address,
    abi: Smartcontract.minerpunkNFT.abi,
    functionName: "mint",
    args: [value],
    account: address,
    value: payment,
  });

  const { data, isLoading, isSuccess, write, status } =
    useContractWrite(config);

  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (waitForTransaction.isSuccess) {
      TXInfo("Success", LangPackage.evn.success, "success");
    }
  }, [waitForTransaction.isSuccess]);

  useEffect(() => {
    if (status == "error") {
      TXInfo("Error", LangPackage.evn.failDesc, "error");
    }
  }, [status]);

  const MintBtn = (
    <div>
      {address ? (
        !(balance > total) ? (
          <Button isDisabled colorScheme="red" size="sm">
            {total} ETH {LangPackage.Mint.Insufficientbalance}
          </Button>
        ) : !(inventory <= 0) ? (
          waitForTransaction.isLoading || isLoading ? (
            <Button
              isLoading
              size="sm"
              colorScheme="teal"
              variant="solid"
              width="140px"
            >
              Email
            </Button>
          ) : (
            <Button
              className="cosShadow"
              colorScheme="teal"
              size="sm"
              isDisabled={!write}
              onClick={() => write?.()}
              width="140px"
            >
              {total}
              {"   "}ETH
            </Button>
          )
        ) : (
          <Button isDisabled colorScheme="red" size="sm">
            {LangPackage.Mint.SoldOut}
          </Button>
        )
      ) : (
        <div>{Web3Button()}</div>
      )}
    </div>
  );

  return (
    <>
      <div
        className="column_center"
        style={{ justifyContent: "space-around", minHeight: "40vh" }}
      >
        <div>
          <Container>
            <Row style={{ alignItems: "flex-end" }}>
              <Col className="column_center">
                {NFTSold()}
                <p className="textStyle"> {LangPackage.Mint.Sold}</p>
              </Col>

              <Col className="column_center">
                <h3 style={{ color: "#59c9c8", fontSize: "45px" }}>
                  {inventory}
                </h3>
                <p
                  className="textStyle"
                  style={{ color: "#59c9c8", fontWeight: "bold" }}
                >
                  {LangPackage.Mint.Inventory}
                </p>
              </Col>

              <Col className="column_center">
                {NFTOpen()}
                <p className="textStyle">{LangPackage.Mint.Open}</p>
              </Col>
            </Row>
          </Container>
          <hr style={{ marginTop: "15px" }}></hr>
        </div>

        <div
          className="column_center"
          style={{ marginTop: "15px", color: "black" }}
        >
          <div>
            <Button onClick={() => sub()}>-</Button>
            <Box
              as="span"
              w="200px"
              mx="24px"
              style={{ color: "white", fontWeight: "bold" }}
            >
              {value}
            </Box>
            <Button onClick={() => add()}>+</Button>
          </div>
        </div>

        <div style={{ marginTop: "15px" }}>{MintBtn}</div>
        <Text color="white" fontSize="lg" as="b">
          {address ? <div> Balance : {balance} ETH</div> : <div></div>}
        </Text>
      </div>
    </>
  );
}

function TextCarousel(props) {
  const { texts } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2500);

    // 清理計時器
    return () => clearInterval(timer);
  }, [texts]);

  return (
    <Text color="white" fontSize="lg" as="b">
      {texts[currentIndex]}
    </Text>
  );
}

export function MintCard() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  const texts = [
    LangPackage.Mint.FeatureA,
    LangPackage.Mint.FeatureB,
    LangPackage.Mint.FeatureC,
    LangPackage.Mint.FeatureD,
  ];

  return (
    <ChakraProvider>
      <Container className="section_style_08">
        <Container style={{ margin: "30px 3px" }}>
          <div>
            <Row>
              <Col md="6" className="column_center">
                <div className="pngShadow02"></div>
                <div style={{ margin: "15px 0px 30px 0px" }}>
                  <TextCarousel texts={texts} />
                </div>
                <p className="textStyle" style={{ margin: "10px 0px " }}>
                  <span
                    style={{
                      color: "#59c9c8",
                      fontWeight: "bold",
                      fontSize: "22px",
                    }}
                  >
                    {NFTPrice()} ETH {""}
                  </span>
                  / Minerpunk
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "15px 0px 30px 0px",
                  }}
                >
                  {Socialmedia("X2Y2")}
                  {Socialmedia("looksrares")}
                </div>
              </Col>
              <Col
                md="6"
                className="column_center contentB"
                style={{ minHeight: "58vh" }}
              >
                {Mintboard()}
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
    </ChakraProvider>
  );
}

function Chart() {
  const proportion = (x, y) => {
    let _proportion = ((x / y) * 100).toFixed(3);
    return _proportion;
  };
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);
  const state = {
    series: [
      proportion(CirculatingSupply(), 100000000),
      proportion(DcoffMineBalnance(), 100000000),
      proportion(Burned(), 100000000),
    ],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "12px",
            },
            value: {
              fontSize: "16px",
              color: "white",
            },
          },
        },
      },
      labels: [
        LangPackage.Token.CirculatingSupply,
        LangPackage.Token.Locked,
        LangPackage.Token.Burned,
      ],
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="radialBar"
        height={300}
      />
    </div>
  );
}

export function TokenInfo() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  const addr = Addr();

  const totalLocked =
    Number(DcoffMineBalnance()) + Number(DcoffMDMineBalnance());

  const assetInfo = {
    CirculatingSupply: {
      id: "CirculatingSupply",
      title: LangPackage.Token.CirculatingSupply,
      amount: CirculatingSupply(),
      percent: _Percent(CirculatingSupply(), 100000000),
      img: _CirculatingSupply,
    },
    locked: {
      id: "locked",
      title: LangPackage.Token.Locked,
      amount: totalLocked,
      percent: _Percent(totalLocked, 100000000),
      img: _locked,
    },
    burn: {
      id: "burn",
      title: LangPackage.Token.Burned,
      amount: Burned(),
      percent: _Percent(Burned(), 100000000),
      img: _burn,
    },
  };

  const asset = (data) => {
    return (
      <div className="depictionCard" style={{ width: "350px" }}>
        <Row>
          <Col xs={4} className="center">
            <img alt={data.id} width="30" src={data.img} />
          </Col>
          <Col>
            <Text color="#849cad" fontSize="sm">
              {data.title} :
            </Text>
            <Text color="white" fontSize="sm">
              {data.amount}
            </Text>
            <Text color="white" fontSize="sm">
              {data.percent}{" "}
            </Text>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Container
      style={{
        justifyContent: "space-around",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Row>
        <Col lg={4} className="column_center " style={{ margin: "30px 0px" }}>
          {AccountCard()}

          {/* token info card */}
          <div
            className="depictionCard"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "350px",
              height: "150px",
              margin: "25px 0px",
            }}
          >
            {!addr ? (
              <div className="content center" style={{ height: "200px" }}>
                <Web3Button />
              </div>
            ) : (
              <></>
            )}

            <div className="column_center">
              <a
                href="https://to101380.gitbook.io/minerpunk/about-minerpunk/dcoff"
                target="_blank"
              >
                <img
                  className="DCFLight"
                  alt={myDcoff}
                  src={myDcoff}
                  width="60px"
                ></img>
              </a>
              <Text color="#849cad" fontSize="sm">
                DCOFF (DCF)
              </Text>
            </div>
            <div className="center" style={{ height: "15vh" }}>
              <div>
                <Text color="white" fontSize="lg" as="b">
                  $ {GetDCFpriceUSD()}
                </Text>
                <Text color="#849cad" fontSize="sm">
                  Type : ERC20
                  <br></br>
                  <a
                    href="https://etherscan.io/address/0xc74ab3ff349ce64a349854bcd5dc2d542371350f"
                    target="_blank"
                  >
                    Address : 0xC74...350F
                  </a>
                </Text>
                {LinkPort("uniswap")}
              </div>
            </div>
          </div>
        </Col>
        <Col lg={4} className="column_center " style={{ margin: "30px 0px" }}>
          {/* difficult chart*/}
          <div className="column_center">
            <Text as="b" color="White" fontSize="sm">
              {LangPackage.Token.MaxSupply} : 100000000
            </Text>
            <Text as="b" color="White" fontSize="sm">
              {LangPackage.Token.TotalSupply} : {TotalSupply()}
            </Text>
            <div>{Chart()}</div>
          </div>
        </Col>
        <Col
          lg={4}
          className="column_center cosGradient"
          style={{ margin: "30px 0px" }}
        >
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <Divider orientation="horizontal" style={{ margin: "15px 0px" }} /> */}
            {/* token info card -2*/}
            {asset(assetInfo["CirculatingSupply"])}
            {asset(assetInfo["locked"])}
            {asset(assetInfo["burn"])}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function AccountCard() {
  return (
    <>
      <Card
        style={{
          background: "url('https://i.imgur.com/6tgta8D.jpg')",
          width: "360px",
          height: "220px",
          cursor: "pointer",
          marginTop: "30px",
        }}
      >
        <div>
          <img
            style={{
              position: "absolute",
              left: "25px",
              top: "25px",
              height: "30px",
            }}
            src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
            class="card-item__chip"
            alt="credit card chip"
          ></img>
          <img
            style={{
              position: "absolute",
              right: "25px",
              top: "30px",
              width: "130px",
            }}
            src={LogoWord}
            class="card-item__chip"
            alt="credit card chip"
          ></img>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "20px 10px",
              height: "100px",
            }}
          >
            <img
              alt="mine"
              src={Dcofficon}
              width="35px"
              className="pngShadow"
            ></img>
            <div
              color="white"
              as="b"
              style={{
                fontSize: "20px",
              }}
            >
              {DocffBalnance()} DCF
            </div>
          </div>
        </div>
        <div>
          <label
            style={{
              color: "white",
              position: "absolute",
              bottom: "50px",
              left: "25px",
              opacity: 0.8,
            }}
          >
            Card holder
          </label>
        </div>

        <div>
          <label
            style={{
              color: "white",
              position: "absolute",
              bottom: "25px",
              left: "25px",
              opacity: 1,
              fontSize: "10px",
            }}
          >
            {Addr()}
          </label>
        </div>
      </Card>

      <div
        style={{
          width: "360px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "0px 15px",
          }}
        >
          {" "}
          ≈<img alt="ETH" src={ETHicon} width="35px"></img>
          <div className="cos_word">{DocffBalnanceETH()}</div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "0px 15px",
          }}
        >
          ≈<img alt="USD" src={USDicon} width="35px"></img>
          <div className="cos_word">{DocffBalnanceUSD()}</div>
        </div>
      </div>
    </>
  );
}

function NFTSold() {
  const { data } = useContractRead({
    address: Smartcontract.minerpunkNFT.address,
    abi: Smartcontract.minerpunkNFT.abi,
    functionName: "ViewInfo",
    args: [0, 3],
  });

  return Number(data);
}

function NFTOpen() {
  const { data } = useContractRead({
    address: Smartcontract.minerpunkNFT.address,
    abi: Smartcontract.minerpunkNFT.abi,
    functionName: "ViewInfo",
    args: [0, 4],
  });

  return Number(data);
}

export function MaxSupply() {
  const { data } = useContractRead({
    address: Smartcontract.minerpunkNFT.address,
    abi: Smartcontract.minerpunkNFT.abi,
    functionName: "maxSupply",
  });

  return Number(data);
}

export function NFTtotalSupply() {
  const { data } = useContractRead({
    address: Smartcontract.minerpunkNFT.address,
    abi: Smartcontract.minerpunkNFT.abi,
    functionName: "totalSupply",
  });

  return Number(data);
}

export function NFTBalance() {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.minerpunkNFT.address,
    abi: Smartcontract.minerpunkNFT.abi,
    functionName: "balanceOf",
    args: [address],
  });

  let Data = Number(data);
  if (isNaN(Data)) return 0;

  return Data;
}

export function MinePower() {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.minerpunkNFT.address,
    abi: Smartcontract.minerpunkNFT.abi,
    functionName: "mineInfo",
    args: [address, 2],
    watch: true,
  });

  let Data = Number(data);
  if (isNaN(Data)) return 0;

  return Data;
}

function TotalPower() {
  const { data } = useContractRead({
    address: Smartcontract.minerpunkNFT.address,
    abi: Smartcontract.minerpunkNFT.abi,
    functionName: "totalPower",
    watch: true,
  });

  return Number(data);
}

function Difficulty() {
  let blocknum = BlockNum();
  let data = BlockStatus(blocknum, 0);
  const [mineStatus, setmineStatus] = useState(0);

  useEffect(() => {
    if (!isNaN(data) || data !== 0) {
      setmineStatus(data);
    }
  }, [data]);

  return Number((1 - mineStatus / 80000000) * 100).toFixed(3);
}

function DugUp() {
  let blocknum = BlockNum();
  const blockStatusMinus1 = BlockStatus(blocknum, -1);
  const blockStatus0 = BlockStatus(blocknum, 0);

  const data = blockStatusMinus1 - blockStatus0;
  return data;
}

function MyDugUp() {
  let maxDugup = DugUp();
  let data = (maxDugup * MinePower()) / TotalPower();
  data = data.toFixed(8);
  return data;
}

function Dayincome() {
  let blocknum = BlockNum();
  const [income, setIncome] = useState(0);
  let data = BlockStatus(blocknum, 0) - BlockStatus(blocknum, 7200);

  useEffect(() => {
    if (data !== 0) {
      setIncome(data);
    }
  }, [data]);

  return income;
}

function Monthincome() {
  let blocknum = BlockNum();
  const [income, setIncome] = useState(0);
  let data = BlockStatus(blocknum, 0) - BlockStatus(blocknum, 216000);

  useEffect(() => {
    if (data !== 0) {
      setIncome(data);
    }
  }, [data]);

  return income;
}

function Yearincome() {
  let blocknum = BlockNum();
  const [income, setIncome] = useState(0);
  let data = BlockStatus(blocknum, 0) - BlockStatus(blocknum, 2628000);

  useEffect(() => {
    if (data !== 0) {
      setIncome(data);
    }
  }, [data]);

  return income;
}

function myIncome(i) {
  let data = (i * MinePower()) / TotalPower();
  return data.toFixed(4);
}

function MyincomeETH(i) {
  let data = (i * MinePower()) / TotalPower();
  data = data * GetDCFpriceETH();
  return data.toFixed(4);
}

function MyincomeUSD(i) {
  let data = (i * MinePower()) / TotalPower();
  data = data * GetDCFpriceUSD();
  return data.toFixed(4);
}

function DifficultyBoard() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);
  const [difficultyValue, setDifficultyValue] = useState(0);
  let _Difficulty = Difficulty();
  let _Dugup = Number(DugUp()).toFixed(6);
  let blocknum = BlockNum();

  useEffect(() => {
    if (!isNaN(_Difficulty)) {
      setDifficultyValue(_Difficulty);
    }
  }, [_Difficulty]);

  const level = ["Easy", "Normal", "Hard"];

  const state = {
    series: [difficultyValue],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#1f202b",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#ffffff",
              fontSize: "20px",
            },
            value: {
              color: "#ffffff",
              fontSize: "20px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: [LangPackage.Mine.Difficulty],
    },
  };

  return (
    <div style={{ margin: "30px 0px" }}>
      {blocknum == 0 ? (
        <div className="column_center" style={{ margin: "20px" }}>
          <img alt="mining" width="100" src={mining} />
          <Text fontSize="sm" color="white">
            {LangPackage.Mine.fetchDifficulty}
          </Text>
        </div>
      ) : (
        <div id="chart" className="column_center">
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="radialBar"
          />
        </div>
      )}

      <Center>
        <Text fontSize="sm" color="#aaaaaa" align="start">
          <div className="column_center">
            {blocknum == 0 ? (
              <Text fontSize="sm" color="white">
                ...
              </Text>
            ) : (
              <Center>
                {_Difficulty >= 90 ? (
                  <Text as="b" fontSize="md" color="red.500">
                    {level[2]}
                  </Text>
                ) : _Difficulty >= 50 && _Difficulty < 90 ? (
                  <Text as="b" fontSize="md" color="yellow.500">
                    {level[1]}
                  </Text>
                ) : (
                  <Text as="b" fontSize="md" color="green.500">
                    {level[0]}
                  </Text>
                )}
              </Center>
            )}

            <div style={{ display: "flex", alignItems: "center" }}>
              <img alt="mine" src={Dcofficon} width="25px"></img>
              {_Dugup} DCF / Block
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon viewBox="0 0 200 200" color="green.500">
                <path
                  fill="currentColor"
                  d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                />
              </Icon>
              <Text as="b" color="green.500">
                {BlockNum()} Block
              </Text>
            </div>

            <Text fontSize="xs" color="#849cad">
              {LangPackage.Mine.dugupintro}
            </Text>
          </div>
        </Text>
      </Center>
    </div>
  );
}

function PowerBoard() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);
  const erc1155balance = ERC1155balance(1);
  return (
    <ChakraProvider>
      <Container className="section_style_07" style={{ minHeight: "40vh" }}>
        <Row style={{ margin: "30px 0px" }}>
          <Col md="6" className="column_center ">
            {erc1155balance > 0 ? (
              <a
                href="https://x2y2.io/eth/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D/1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="mainLogo" src={mainLogoERC1155} width="180px"></img>
              </a>
            ) : (
              <img alt="mainLogo" src={mainLogo} width="180px"></img>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ margin: "0px 10px" }}>{LinkPort("snapshot")}</div>
              {Socialmedia("X2Y2")}
              {Socialmedia("looksrares")}
            </div>
          </Col>
          <Col md="6" className="column_center">
            <div
              className="contentB"
              style={{ margin: "25px 0px", padding: "15px" }}
            >
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ color: "#59c9c8", fontSize: "18px" }}>
                  {LangPackage.Mine.MinePower}
                </div>
                <img
                  style={{ margin: "0px 8px" }}
                  alt="energy"
                  src={energy}
                  width="15px"
                />
                <div style={{ fontSize: "18px" }}>
                  {_Percent(MinePower(), TotalPower())}
                </div>
              </div>

              <div
                className="center"
                style={{ width: "250px", margin: "20px 0px" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ margin: "0px 10px" }}>
                    {" "}
                    {MinePower() > 0 ? (
                      <img alt="mining" src={mining} width="30px"></img>
                    ) : (
                      <img alt="mining" src={stopMine} width="30px"></img>
                    )}
                  </div>

                  <div style={{ fontSize: "15px", color: "white" }}>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "40px",
                        color: "white",
                        margin: "5px",
                      }}
                    >
                      {MinePower()}
                    </span>
                    / {TotalPower()}
                  </div>
                </div>
              </div>

              <Text
                as="b"
                fontSize="md"
                color="white"
                style={{ margin: "0px 10px" }}
              >
                {LangPackage.Mine.NFTBalance} : {NFTBalance()}
              </Text>
            </div>

            <Link to={`/MinerpunkDungeon/MDMintCard/${paramName}`}>
              <div className="gradient-button">
                {LangPackage.Mine.EnterDungeon}
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </ChakraProvider>
  );
}

function Income() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  let blocknum = BlockNum();
  let price = GetDCFpriceETH();

  let ExpectedProfit = [
    {
      id: "daily",
      title: LangPackage.Mine.ExpectedDailyReturn,
      DCF: myIncome(Dayincome()),
      ETH: MyincomeETH(Dayincome()),
      USD: MyincomeUSD(Dayincome()),
    },
    {
      id: "monthly",
      title: LangPackage.Mine.ExpectedMonthlyReturn,
      DCF: myIncome(Monthincome()),
      ETH: MyincomeETH(Monthincome()),
      USD: MyincomeUSD(Monthincome()),
    },
    {
      id: "annual",
      title: LangPackage.Mine.ExpectedAnnualReturn,
      DCF: myIncome(Yearincome()),
      ETH: MyincomeETH(Yearincome()),
      USD: MyincomeUSD(Yearincome()),
    },
  ];

  return (
    <div
      style={{
        justifyContent: "space-around",
        display: "flex",
        flexDirection: "column",
        height: "550px",
        margin: "15px 0px",
      }}
    >
      {ExpectedProfit.map((item) => (
        <div className="column_center" key={item.id}>
          <div style={{ width: "320px" }} className="depictionCard">
            <Text fontSize="lg" color="white" as="b">
              <Text fontSize="xs" color="#849cad" style={{ margin: "10px" }}>
                {item.title}
              </Text>

              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0px",
                  }}
                >
                  <img alt="Dcofficon" src={Dcofficon} width="35px"></img>
                  {blocknum == 0 ? (
                    <Skeleton height="15px">
                      <div>contents wrapped</div>
                      <div>won't be visible</div>
                    </Skeleton>
                  ) : (
                    <>
                      {item.DCF}
                      {"  "}DCF
                    </>
                  )}
                </div>
              </div>

              <Text fontSize="xs" color="#849cad">
                <div
                  className="row_center"
                  style={{ justifyContent: "space-between", marginLeft: "4px" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img alt="USDicon" src={USDicon} width="25px"></img>
                    {isNaN(price) ? (
                      <Skeleton Skeleton height="15px">
                        <div>contents wrapped</div>
                        <div>won't be visible</div>
                      </Skeleton>
                    ) : (
                      <>${item.USD}</>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "30px",
                    }}
                  >
                    <img alt="ETHicon" src={ETHicon} width="25px"></img>
                    {isNaN(price) ? (
                      <Skeleton Skeleton height="15px">
                        <div>contents wrapped</div>
                        <div>won't be visible</div>
                      </Skeleton>
                    ) : (
                      <>{item.ETH} ETH</>
                    )}
                  </div>
                </div>
              </Text>
            </Text>
          </div>
        </div>
      ))}
    </div>
  );
}

export function IncomeBoard() {
  return (
    <Container style={{ width: "700px" }}>
      <Row>
        <Container
          className="column_center cosGradient"
          style={{ minHeight: "30vh" }}
        >
          {PowerBoard()}
          <Divider />
        </Container>

        <Col md="6" className="column_center ">
          {DifficultyBoard()}
        </Col>
        <Col md="6" className="column_center cosGradient">
          {Income()}
        </Col>
      </Row>
    </Container>
  );
}

export function ViewReward() {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.DcoffMine.address,
    abi: Smartcontract.DcoffMine.abi,
    functionName: "viewReward",
    args: [address],
    watch: true,
  });

  let preReward = Number(data);
  preReward = (preReward / 1e18).toFixed(6);

  const [reward, setreward] = useState(preReward);
  useEffect(() => {
    setTimeout(() => {
      setreward(preReward);
    }, 3000);
  }, [preReward]);

  return (
    <>
      <CountUp start={reward} end={preReward} duration={5} decimals={4} />
    </>
  );
}

export function ViewRewardETH() {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.DcoffMine.address,
    abi: Smartcontract.DcoffMine.abi,
    functionName: "viewReward",
    args: [address],
    watch: true,
  });

  let _reward = Number(data);
  _reward = _reward / 1e18;
  _reward = (_reward * GetDCFpriceETH()).toFixed(6);
  if (isNaN(_reward)) return 0;
  return _reward;
}

export function ViewRewardUSD() {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.DcoffMine.address,
    abi: Smartcontract.DcoffMine.abi,
    functionName: "viewReward",
    args: [address],
    watch: true,
  });

  let _reward = Number(data);
  _reward = _reward / 1e18;
  _reward = (_reward * GetDCFpriceUSD()).toFixed(6);
  if (isNaN(_reward)) return 0;
  return _reward;
}

export function ClaimReward() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  const { config } = usePrepareContractWrite({
    address: Smartcontract.DcoffMine.address,
    abi: Smartcontract.DcoffMine.abi,
    functionName: "Claim",
  });

  const toast = useToast();
  const TXInfo = (_title, _description, _status) => {
    toast({
      position: "top",
      title: _title,
      description: _description,
      status: _status,
      duration: 9000,
      isClosable: true,
    });
  };

  const { data, isLoading, isSuccess, write, status } =
    useContractWrite(config);

  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (waitForTransaction.isSuccess) {
      TXInfo("Success", LangPackage.evn.success, "success");
    }
  }, [waitForTransaction.isSuccess]);

  useEffect(() => {
    if (status == "error") {
      TXInfo("Error", LangPackage.evn.failDesc, "error");
    }
  }, [status]);

  return (
    <div>
      {waitForTransaction.isLoading || isLoading ? (
        <Button
          size="lg"
          isLoading
          colorScheme="messenger"
          variant="solid"
          width="320px"
        >
          Email
        </Button>
      ) : (
        <Button
          size="lg"
          colorScheme="messenger"
          variant="solid"
          isDisabled={!write}
          onClick={() => write?.()}
          width="320px"
        >
          {LangPackage.Miner.Claim}
        </Button>
      )}
    </div>
  );
}

export function SlideFadeEx() {
  const { isOpen, onToggle } = useDisclosure();
  const [alertPrice, setalertPrice] = useState(false);

  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.DcoffMine.address,
    abi: Smartcontract.DcoffMine.abi,
    functionName: "viewReward",
    args: [address],
    watch: true,
  });

  let nftBalance = NFTBalance();

  useEffect(() => {
    if (nftBalance > 0) {
      onToggle();
      setalertPrice(true);
    }
  }, [data]);

  if (alertPrice === true) {
    setTimeout(() => {
      onToggle();
      setalertPrice(false);
    }, 5000);
  }

  return (
    <>
      <SlideFade in={isOpen} offsetY="20px">
        <StatGroup>
          <Stat>
            <StatHelpText
              color="white"
              style={{ display: "flex", alignItems: "center" }}
            >
              <StatArrow type="increase" />+ <MyDugUp />
            </StatHelpText>
          </Stat>
        </StatGroup>
      </SlideFade>
    </>
  );
}

export function ERC1155balance(i) {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.BadgesERC1155.address,
    abi: Smartcontract.BadgesERC1155.abi,
    functionName: "balanceOf",
    args: [address, i],
  });
  return Number(data);
}

export function Badgebar() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  let badgecard = {
    "01": {
      id: "01",
      X2Y2Icon: X2Y2,
      looksrare: looksrare,
      X2Y2URL:
        "https://x2y2.io/eth/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D/1",
      looksrareURL:
        "https://looksrare.org/collections/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D/1",
      title: "#001  " + LangPackage.badge.badge01title,
      description: LangPackage.badge.badge01description,
      messages: LangPackage.badge.messages[1],
      complete: LangPackage.badge.completeinfo[1],
      img: badge01,
      lock_img: lock_badge01,
      badgebalance: ERC1155balance(1) >= 1,
      unfinished: <div className="row_center">not yet obtained</div>,
      mintbtn: MintBadge(1),
    },
    "02": {
      id: "02",
      X2Y2Icon: X2Y2,
      looksrare: looksrare,
      X2Y2URL:
        "https://x2y2.io/eth/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D/2",
      looksrareURL:
        "https://looksrare.org/collections/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D/2",
      title: "#002  " + LangPackage.badge.badge02title,
      description: LangPackage.badge.badge02description,
      messages: LangPackage.badge.messages[2],
      complete: LangPackage.badge.completeinfo[2],
      img: badge02,
      lock_img: lock_badge02,
      badgebalance: ERC1155balance(2) >= 1,
      condition: DocffBalnance() >= 5000,
      unfinished: (
        <div className="row_center">{DocffBalnance()} / 5000 DCF</div>
      ),
      mintbtn: MintBadge(2),
    },
    "03": {
      id: "03",
      X2Y2Icon: X2Y2,
      looksrare: looksrare,
      X2Y2URL:
        "https://x2y2.io/eth/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D/3",
      looksrareURL:
        "https://looksrare.org/collections/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D/3",
      title: "#003  " + LangPackage.badge.badge03title,
      description: LangPackage.badge.badge03description,
      messages: LangPackage.badge.messages[3],
      complete: LangPackage.badge.completeinfo[3],
      img: badge03,
      lock_img: lock_badge03,
      badgebalance: ERC1155balance(3) >= 1,
      unfinished: <div className="row_center">not yet cracked</div>,
      mintbtn: (
        <a
          href="https://etherscan.io/address/0x9a5691a90fddadf0a8b99675d880a2aa2693f432#code"
          target="_blank"
        >
          <Button colorScheme="teal">Crack</Button>
        </a>
      ),
    },
    "04": {
      id: "04",
      X2Y2Icon: X2Y2,
      looksrare: looksrare,
      X2Y2URL:
        "https://x2y2.io/eth/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D/4",
      looksrareURL:
        "https://looksrare.org/collections/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D/4",
      title: "#004  " + LangPackage.badge.badge04title,
      description: LangPackage.badge.badge04description,
      messages: LangPackage.badge.messages[4],
      complete: LangPackage.badge.completeinfo[4],
      img: badge04,
      lock_img: lock_badge04,
      badgebalance: ERC1155balance(4) >= 1,
      condition: MinePower() >= 20,
      unfinished: <div className="row_center">{MinePower()} / 20 Power</div>,
      mintbtn: MintBadge(4),
    },
    "05": {
      id: "05",
      X2Y2Icon: X2Y2,
      looksrare: looksrare,
      X2Y2URL:
        "https://x2y2.io/eth/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D/5",
      looksrareURL:
        "https://looksrare.org/collections/0x73b8CeB6D96202c37D2d168931D3e4D4F33e8c7D/5",
      title: "#005  " + LangPackage.badge.badge05title,
      description: LangPackage.badge.badge05description,
      messages: LangPackage.badge.messages[5],
      complete: LangPackage.badge.completeinfo[5],
      img: badge05,
      lock_img: lock_badge05,
      badgebalance: ERC1155balance(5) >= 1,
      condition: BadgeAmount() >= 4,
      unfinished: <div className="row_center">{BadgeAmount()} / 4 Badges</div>,
      mintbtn: MintBadge(5),
    },
  };

  function MintBadge(i) {
    const toast = useToast();
    const TXInfo = (_title, _description, _status) => {
      toast({
        position: "top",
        title: _title,
        description: _description,
        status: _status,
        duration: 9000,
        isClosable: true,
      });
    };

    const { config, error } = usePrepareContractWrite({
      address: Smartcontract.BadgesController.address,
      abi: Smartcontract.BadgesController.abi,
      functionName: "AwardBadge",
      args: [i],
    });

    const { data, isLoading, isSuccess, write, status } =
      useContractWrite(config);

    const waitForTransaction = useWaitForTransaction({
      hash: data?.hash,
    });

    useEffect(() => {
      if (waitForTransaction.isSuccess) {
        TXInfo("Success", LangPackage.evn.success, "success");
      }
    }, [waitForTransaction.isSuccess]);

    useEffect(() => {
      if (status == "error") {
        TXInfo("Error", LangPackage.evn.failDesc, "error");
      }
    }, [status]);

    return (
      <div>
        {ERC1155balance(i) >= 1 ? (
          <div>Minted</div>
        ) : waitForTransaction.isLoading || isLoading ? (
          <Button isLoading size="sm" colorScheme="teal" variant="solid">
            Email
          </Button>
        ) : (
          <Button
            className="cosShadow"
            colorScheme="teal"
            size="lg"
            isDisabled={!write}
            onClick={() => write?.()}
          >
            Mint
          </Button>
        )}
      </div>
    );
  }

  function BadgeAmount() {
    let amount = 0;
    for (let i = 1; i <= 4; i++) {
      if (ERC1155balance(i) >= 1) {
        amount += 1;
      }
    }
    return amount;
  }

  const BadgeInfo = (data) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
      <>
        <Col
          className="column_center "
          md="4"
          style={{ margin: "18px 0px" }}
          onClick={onOpen}
        >
          {data.badgebalance ? (
            <div className="cosCardA">
              <img alt={data.id} src={data.img} />
            </div>
          ) : (
            <div className="cosCardA">
              <img alt={data.id} src={data.lock_img} />
            </div>
          )}

          <div className="cos_word">{data.title}</div>
        </Col>

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent className="center" style={{ margin: "10px" }}>
            <div className="contentA">
              <Text color="white">
                <ModalHeader>{data.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Card>
                    {data.badgebalance ? (
                      <img alt={data.id} src={data.img} />
                    ) : (
                      <img alt={data.id} src={data.lock_img} />
                    )}
                  </Card>
                  <Tabs colorScheme="white" variant="enclosed">
                    <div
                      className="column_center"
                      style={{ margin: "15px 0px" }}
                    >
                      <div
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <TabList>
                          <Tab>{LangPackage.badge.tab.task}</Tab>
                          <Tab>{LangPackage.badge.tab.directions}</Tab>
                        </TabList>

                        <div className="row_center">
                          {Media(data.X2Y2Icon, data.X2Y2URL)}
                          {Media(data.looksrare, data.looksrareURL)}
                        </div>
                      </div>
                    </div>
                    <TabPanels>
                      <TabPanel>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "20vh",
                            overflow: "auto",
                            marginTop: "30px",
                          }}
                        >
                          {data.badgebalance ? (
                            <>
                              <div className="row_center">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0px 10px",
                                  }}
                                >
                                  <Icon
                                    color="green.500"
                                    as={CheckCircleIcon}
                                  />
                                </div>
                                <Text fontSize="lg" as="b">
                                  {data.complete}
                                </Text>
                              </div>
                              <Text
                                fontSize="sm"
                                color="white"
                                style={{ margin: "15px 0px" }}
                              >
                                {data.messages}
                              </Text>
                            </>
                          ) : (
                            <>
                              <div className="row_center">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0px 10px",
                                  }}
                                >
                                  {data.condition ? (
                                    <Icon
                                      color="green.500"
                                      as={CheckCircleIcon}
                                    />
                                  ) : (
                                    <Icon color="grey" as={CheckCircleIcon} />
                                  )}
                                </div>
                                <Text fontSize="lg" as="b">
                                  {data.unfinished}
                                </Text>
                              </div>
                              <Text
                                fontSize="sm"
                                color="white"
                                style={{ margin: "15px 0px" }}
                              >
                                {data.messages}
                              </Text>
                              {data.condition == true ||
                              data.condition == undefined ? (
                                data.mintbtn
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div
                          style={{
                            height: "20vh",
                            overflow: "auto",
                            marginTop: "30px",
                          }}
                        >
                          <Text
                            fontSize="md"
                            color="white"
                            style={{ margin: "15px 0px" }}
                          >
                            {data.description}
                          </Text>
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </ModalBody>
              </Text>
            </div>
          </ModalContent>
        </Modal>
      </>
    );
  };

  return (
    <>
      <Container className="section_style_09">
        <Row>
          {BadgeInfo(badgecard["01"])}
          {BadgeInfo(badgecard["02"])}
          {BadgeInfo(badgecard["03"])}
        </Row>
        <Row>
          {BadgeInfo(badgecard["04"])}
          {BadgeInfo(badgecard["05"])}
          <Col md="4" className="column_center">
            <img
              alt="mainLogo"
              src="https://i.imgur.com/QCBzKl2.png"
              width="200px"
              style={{ margin: "0px 50px" }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Dungeon

export function DcoffamountCondition() {
  const { data } = useContractRead({
    address: Smartcontract.Dungeon.address,
    abi: Smartcontract.Dungeon.abi,
    functionName: "DcoffamountCondition",
  });

  return Number(data) / 1e18;
}

export function PowerCondition() {
  const { data } = useContractRead({
    address: Smartcontract.Dungeon.address,
    abi: Smartcontract.Dungeon.abi,
    functionName: "PowerCondition",
  });

  return Number(data);
}

export function MinerpunkDungeonPrice() {
  const { data } = useContractRead({
    address: Smartcontract.Dungeon.address,
    abi: Smartcontract.Dungeon.abi,
    functionName: "Price",
  });

  return Number(data) / 1e18;
}

export function MDbalanceOf() {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.Dungeon.address,
    abi: Smartcontract.Dungeon.abi,
    functionName: "balanceOf",
    args: [address],
  });

  return Number(data);
}

export function MDmaxSupply() {
  const { data } = useContractRead({
    address: Smartcontract.Dungeon.address,
    abi: Smartcontract.Dungeon.abi,
    functionName: "maxSupply",
  });

  return Number(data);
}

export function MDtotalSupply() {
  const { data } = useContractRead({
    address: Smartcontract.Dungeon.address,
    abi: Smartcontract.Dungeon.abi,
    functionName: "totalSupply",
  });

  return Number(data);
}

export function MDdugUp() {
  let blocknum = BlockNum();
  const blockStatusMinus1 = MDBlockStatus(blocknum, -1);
  const blockStatus0 = MDBlockStatus(blocknum, 0);

  const data = blockStatusMinus1 - blockStatus0;
  return data;
}

export function MyMDdugUp() {
  let maxDugup = MDdugUp();
  let data = (maxDugup * MDbalanceOf()) / MDmaxSupply();
  data = data.toFixed(8);
  return Number(data);
}

export function MDDayincome() {
  let blocknum = BlockNum();
  const [income, setIncome] = useState(0);
  let data = MDBlockStatus(blocknum, 0) - MDBlockStatus(blocknum, 7200);

  useEffect(() => {
    if (data !== 0) {
      setIncome(data);
    }
  }, [data]);

  return income;
}

export function MDMonthincome() {
  let blocknum = BlockNum();
  const [income, setIncome] = useState(0);
  let data = MDBlockStatus(blocknum, 0) - MDBlockStatus(blocknum, 216000);

  useEffect(() => {
    if (data !== 0) {
      setIncome(data);
    }
  }, [data]);

  return income;
}

export function MDYearincome() {
  let blocknum = BlockNum();
  const [income, setIncome] = useState(0);
  let data = MDBlockStatus(blocknum, 0) - MDBlockStatus(blocknum, 2628000);

  useEffect(() => {
    if (data !== 0) {
      setIncome(data);
    }
  }, [data]);

  return income;
}

export function myMDIncome(i) {
  let data = (i * MDbalanceOf()) / MDmaxSupply();
  return data.toFixed(4);
}

export function MyMDincomeETH(i) {
  let data = (i * MDbalanceOf()) / MDmaxSupply();
  data = data * GetDCFpriceETH();
  return data.toFixed(4);
}

export function MyMDincomeUSD(i) {
  let data = (i * MDbalanceOf()) / MDmaxSupply();
  data = data * GetDCFpriceUSD();
  return data.toFixed(4);
}

export function ViewMDReward() {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.DungeonMiner.address,
    abi: Smartcontract.DungeonMiner.abi,
    functionName: "viewReward",
    args: [address],
    watch: true,
  });

  let preReward = Number(data);
  preReward = (preReward / 1e18).toFixed(6);

  const [reward, setreward] = useState(preReward);
  useEffect(() => {
    setTimeout(() => {
      setreward(preReward);
    }, 3000);
  }, [preReward]);

  return (
    <>
      <CountUp start={reward} end={preReward} duration={5} decimals={4} />
    </>
  );
}

export function ViewMDRewardETH() {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.DungeonMiner.address,
    abi: Smartcontract.DungeonMiner.abi,
    functionName: "viewReward",
    args: [address],
    watch: true,
  });

  let _reward = Number(data);
  _reward = _reward / 1e18;
  _reward = (_reward * GetDCFpriceETH()).toFixed(6);
  if (isNaN(_reward)) return 0;
  return _reward;
}

export function ViewMDRewardUSD() {
  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.DungeonMiner.address,
    abi: Smartcontract.DungeonMiner.abi,
    functionName: "viewReward",
    args: [address],
    watch: true,
  });

  let _reward = Number(data);
  _reward = _reward / 1e18;
  _reward = (_reward * GetDCFpriceUSD()).toFixed(6);
  if (isNaN(_reward)) return 0;
  return _reward;
}

export function MDSlideFadeEx() {
  const { isOpen, onToggle } = useDisclosure();
  const [alertPrice, setalertPrice] = useState(false);

  const { address } = useAccount();
  const { data } = useContractRead({
    address: Smartcontract.DungeonMiner.address,
    abi: Smartcontract.DungeonMiner.abi,
    functionName: "viewReward",
    args: [address],
    watch: true,
  });

  let nftBalance = MDbalanceOf();

  useEffect(() => {
    if (nftBalance > 0) {
      onToggle();
      setalertPrice(true);
    }
  }, [data]);

  if (alertPrice === true) {
    setTimeout(() => {
      onToggle();
      setalertPrice(false);
    }, 5000);
  }

  return (
    <>
      <SlideFade in={isOpen} offsetY="20px">
        <StatGroup>
          <Stat>
            <StatHelpText
              color="white"
              style={{ display: "flex", alignItems: "center" }}
            >
              <StatArrow type="increase" />+ <MyMDdugUp />
            </StatHelpText>
          </Stat>
        </StatGroup>
      </SlideFade>
    </>
  );
}

export const IsComplete = () => {
  const [isComplete, setCompelte] = useState(false);

  let _MinePower = MinePower();
  let _PowerCondition = PowerCondition();
  let _DocffBalnance = DocffBalnance();
  let _DcoffamountCondition = DcoffamountCondition();
  let _MDbalanceOf = MDbalanceOf();

  useEffect(() => {
    if (
      (_MinePower >= _PowerCondition &&
        _DocffBalnance >= _DcoffamountCondition) ||
      _MDbalanceOf > 0
    ) {
      setCompelte(true);
    } else {
      setCompelte(false);
    }
  }, [
    _MinePower,
    _PowerCondition,
    _DocffBalnance,
    _DcoffamountCondition,
    _MDbalanceOf,
  ]);

  return isComplete;
};

const IsTask = () => {
  const [isComplete, setCompelte] = useState(false);

  let _MinePower = MinePower();
  let _PowerCondition = PowerCondition();
  let _DocffBalnance = DocffBalnance();
  let _DcoffamountCondition = DcoffamountCondition();

  useEffect(() => {
    if (
      _MinePower >= _PowerCondition &&
      _DocffBalnance >= _DcoffamountCondition
    ) {
      setCompelte(true);
    } else {
      setCompelte(false);
    }
  }, [_MinePower, _PowerCondition, _DocffBalnance, _DcoffamountCondition]);

  return isComplete;
};

export function MDmintCard() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);
  const { address } = useAccount();
  let [value, setValue] = useControllableState({ defaultValue: 1 });

  let balance = Number(Balance());
  let inventory = MDmaxSupply() - MDtotalSupply();
  const total = Number((value * MinerpunkDungeonPrice()).toFixed(2));
  const payment = (total * 1e18).toString();

  const profit = ((MDDayincome() * value) / MDmaxSupply()).toFixed(4);
  const profit_USD = (profit * GetDCFpriceUSD()).toFixed(4);
  const profit_ETH = (profit * GetDCFpriceETH()).toFixed(4);

  const toast = useToast();
  const TXInfo = (_title, _description, _status) => {
    toast({
      position: "top",
      title: _title,
      description: _description,
      status: _status,
      duration: 9000,
      isClosable: true,
    });
  };

  function sub() {
    if (value <= 1) {
      setValue((value = 1));
    } else {
      setValue(value - 1);
    }
  }

  function add() {
    if (value >= inventory) {
      setValue((value = inventory));
    } else {
      setValue(value + 1);
    }
  }

  const { config, error } = usePrepareContractWrite({
    address: Smartcontract.Dungeon.address,
    abi: Smartcontract.Dungeon.abi,
    functionName: "mint",
    args: [value],
    account: address,
    value: payment,
  });

  const { data, isLoading, isSuccess, write, status } =
    useContractWrite(config);

  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (waitForTransaction.isSuccess) {
      TXInfo("Success", LangPackage.evn.success, "success");
    }
  }, [waitForTransaction.isSuccess]);

  useEffect(() => {
    if (status == "error") {
      TXInfo("Error", LangPackage.evn.failDesc, "error");
    }
  }, [status]);

  const MintBtn = (
    <div>
      {address ? (
        !(balance > total) ? (
          <Button isDisabled colorScheme="red" size="sm" width="200px">
            {total} ETH {LangPackage.Mint.Insufficientbalance}
          </Button>
        ) : !(inventory <= 0) ? (
          waitForTransaction.isLoading || isLoading ? (
            <Button
              isLoading
              size="sm"
              colorScheme="teal"
              variant="solid"
              width="200px"
              height="50px"
            >
              Email
            </Button>
          ) : (
            <Button
              className="cosShadow"
              colorScheme="teal"
              size="sm"
              isDisabled={!write}
              onClick={() => write?.()}
              width="200px"
              height="50px"
            >
              {total}
              {"   "}ETH
            </Button>
          )
        ) : (
          <Button isDisabled colorScheme="red" size="sm">
            {LangPackage.Mint.SoldOut}
          </Button>
        )
      ) : (
        <div>{Web3Button()}</div>
      )}
    </div>
  );

  return (
    <>
      <div className="contentB column_center" style={{ padding: "35px" }}>
        <div>
          <Text color="white" fontSize="sm">
            {LangPackage.minerpunkDungeon.CurrentDungeonEarnings}
          </Text>

          <div
            style={{
              margin: "18px 0px",
              display: "flex",
              alignItems: "center",
            }}
            className="row_center"
          >
            <img
              className="DCFLight"
              alt={myDcoff}
              src={myDcoff}
              width="40px"
            ></img>
            <Text color="white" fontSize="xl" as="b">
              {profit} DCF{" "}
              <span style={{ fontSize: "12px", color: "#849cad" }}>/ Day </span>
            </Text>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "250px",
            }}
          >
            <Text color="#849cad" fontSize="md">
              ≈ {profit_USD} USD
            </Text>
            <Text color="#849cad" fontSize="md">
              ≈ {profit_ETH} ETH
            </Text>
          </div>
        </div>

        <Divider />

        <Text color="white" fontSize="lg" as="b" style={{ margin: "20px 0px" }}>
          {LangPackage.minerpunkDungeon.StockNotice} : {inventory}
        </Text>

        <div
          className="column_center"
          style={{ justifyContent: "space-around" }}
        >
          <div className="row_center" style={{ margin: "15px 0px" }}>
            <Button onClick={() => sub()}>-</Button>
            <Box style={{ color: "white", margin: "0px 50px" }}>{value}</Box>
            <Button onClick={() => add()}>+</Button>
          </div>
          <div>
            <div className="column_center">
              <div>{MintBtn}</div>
              <Text color="white" fontSize="sm">
                {!IsTask() ? (
                  <>{LangPackage.minerpunkDungeon.QualificationStatus}</>
                ) : (
                  <></>
                )}
              </Text>
            </div>

            <Text color="white" fontSize="md" style={{ marginTop: "15px" }}>
              {address ? <div> Balance : {balance} ETH</div> : <div></div>}
            </Text>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <WarningIcon w={4} h={4} color="yellow.500" />
            <div style={{ fontSize: "12px", margin: "0px 10px" }}>
              {LangPackage.minerpunkDungeon.PriceFluctuationWarning}
            </div>
          </div>
        </div>
      </div>
      <div
        className="contentB column_center"
        style={{ padding: "15px", marginTop: "30px" }}
      >
        <UnorderedList fontSize="md" style={{ margin: "10px 0px" }}>
          <ListItem>{LangPackage.minerpunkDungeon.OwnershipBenefits}</ListItem>
          <ListItem>{LangPackage.minerpunkDungeon.DwarfMiningBenefit}</ListItem>
          <ListItem>{LangPackage.minerpunkDungeon.MaxIssue}</ListItem>
          <ListItem>{LangPackage.minerpunkDungeon.AirdropPrivilege}</ListItem>
        </UnorderedList>
      </div>
    </>
  );
}

export function MDIncomeCard() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);
  let blocknum = BlockNum();

  let ExpectedProfit = [
    {
      id: "daily",
      title: LangPackage.minerpunkDungeon.ExpectedDailyProfit,
      DCF: myMDIncome(MDDayincome()),
      ETH: MyMDincomeETH(MDDayincome()),
      USD: MyMDincomeUSD(MDDayincome()),
    },
    {
      id: "monthly",
      title: LangPackage.minerpunkDungeon.ExpectedMonthlyProfit,
      DCF: myMDIncome(MDMonthincome()),
      ETH: MyMDincomeETH(MDMonthincome()),
      USD: MyMDincomeUSD(MDMonthincome()),
    },
    {
      id: "annual",
      title: LangPackage.minerpunkDungeon.ExpectedYearlyProfit,
      DCF: myMDIncome(MDYearincome()),
      ETH: MyMDincomeETH(MDYearincome()),
      USD: MyMDincomeUSD(MDYearincome()),
    },
  ];

  return (
    <Container className="column_center">
      <div
        style={{
          justifyContent: "space-around",
          display: "flex",
          flexDirection: "column",
          height: "550px",
        }}
      >
        {ExpectedProfit.map((item) => (
          <div className="column_center " key={item.id}>
            <div
              style={{ width: "320px", padding: "15px 10px" }}
              className="contentB"
            >
              <Text fontSize="lg" color="white" as="b">
                <Text fontSize="xs" color="#849cad" style={{ margin: "10px" }}>
                  {item.title}
                </Text>

                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "10px 0px",
                    }}
                  >
                    <img alt="Dcofficon" src={Dcofficon} width="35px"></img>
                    {blocknum == 0 ? (
                      <Skeleton height="15px">
                        <div>contents wrapped</div>
                        <div>won't be visible</div>
                      </Skeleton>
                    ) : (
                      <>
                        {item.DCF}
                        {"  "}DCF
                      </>
                    )}
                  </div>
                </div>

                <Text fontSize="xs" color="#849cad">
                  <div
                    className="row_center"
                    style={{
                      justifyContent: "space-between",
                      marginLeft: "4px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img alt="USDicon" src={USDicon} width="25px"></img>$
                      {item.USD}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "30px",
                      }}
                    >
                      <img alt="ETHicon" src={ETHicon} width="25px"></img>
                      {item.ETH} ETH
                    </div>
                  </div>
                </Text>
              </Text>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}

export function MDMine() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  let blocknum = BlockNum();

  const numberofpeople = NFTtotalSupply() / 61;
  const roundedNumber = Math.round(numberofpeople);

  const Tip = 16; //百分比
  const Tax = ((MyMDdugUp() / Tip) * 100).toFixed(4);

  return (
    <div className="cloumn_center">
      <div
        className="depictionCard contentB"
        style={{ margin: "16px 0px", width: "320px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ color: "#59c9c8" }}>
            {LangPackage.minerpunkDungeon.CurrentProfit}
          </p>
          {MDSlideFadeEx()}
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            className="DCFLight"
            alt={myDcoff}
            src={myDcoff}
            width="40px"
            style={{ marginRight: "10px" }}
          ></img>
          <Text as="b">
            {" "}
            {ViewMDReward()}
            <span style={{ fontSize: "12px", marginLeft: "12px" }}>DCF</span>
          </Text>
        </div>
        <div
          className="row_center"
          style={{
            justifyContent: "space-between",
            marginTop: "15px",
            width: "250px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="USD" src={USDicon} width="35px"></img>
            <div className="cos_word" style={{ color: "#C0C0BE" }}>
              ${ViewMDRewardUSD()}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img alt="ETH" src={ETHicon} width="35px"></img>
            <div className="cos_word" style={{ color: "#C0C0BE" }}>
              {ViewMDRewardETH()}
            </div>
          </div>
        </div>
        {ClaimMDReward()}
      </div>
      <div
        className="depictionCard column_center contentB"
        style={{ width: "320px", padding: "40px 15px" }}
      >
        <div style={{ width: "280px" }}>
          <Text color="#00efcb" fontSize="sm">
            {LangPackage.minerpunkDungeon.BlockHeight} :
          </Text>
        </div>
        <Text color="#00efcb" as="b" fontSize="2xl">
          {blocknum}
        </Text>

        <Divider />
        <div style={{ width: "280px" }}>
          <Text color="white" fontSize="sm">
            {LangPackage.minerpunkDungeon.NumberOfMines} : {MDbalanceOf()}
          </Text>
          <Text color="white" fontSize="sm">
            {LangPackage.minerpunkDungeon.NumberOfMiningDwarfs} :{" "}
            {roundedNumber}
          </Text>
          <Text color="white" fontSize="sm">
            {LangPackage.minerpunkDungeon.CurrentBlockMined} : {Tax} DCF
          </Text>
          <Text color="white" fontSize="sm">
            {LangPackage.minerpunkDungeon.YourTaxRate} : {Tip}%
          </Text>
          <Text color="white" fontSize="sm">
            {LangPackage.minerpunkDungeon.CurrentBlockProfit} :
          </Text>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            className="DCFLight"
            alt={myDcoff}
            src={myDcoff}
            width="20px"
            style={{ marginRight: "10px" }}
          ></img>
          <Text as="b" fontSize="2xl">
            {" "}
            {MyMDdugUp()}
            <span style={{ fontSize: "12px", marginLeft: "12px" }}>DCF</span>
          </Text>
        </div>
      </div>
    </div>
  );
}

export function ClaimMDReward() {
  let { paramName } = useParams();
  const LangPackage = getlangselect(paramName);

  const { config } = usePrepareContractWrite({
    address: Smartcontract.DungeonMiner.address,
    abi: Smartcontract.DungeonMiner.abi,
    functionName: "Claim",
  });

  const toast = useToast();
  const TXInfo = (_title, _description, _status) => {
    toast({
      position: "top",
      title: _title,
      description: _description,
      status: _status,
      duration: 9000,
      isClosable: true,
    });
  };

  const { data, isLoading, isSuccess, write, status } =
    useContractWrite(config);

  const waitForTransaction = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (waitForTransaction.isSuccess) {
      TXInfo("Success", LangPackage.evn.success, "success");
    }
  }, [waitForTransaction.isSuccess]);

  useEffect(() => {
    if (status == "error") {
      TXInfo("Error", LangPackage.evn.failDesc, "error");
    }
  }, [status]);

  return (
    <div>
      {waitForTransaction.isLoading || isLoading ? (
        <Button
          size="lg"
          isLoading
          colorScheme="messenger"
          variant="solid"
          width="300px"
        >
          Email
        </Button>
      ) : (
        <Button
          size="lg"
          colorScheme="messenger"
          variant="solid"
          isDisabled={!write}
          onClick={() => write?.()}
          width="300px"
        >
          {LangPackage.minerpunkDungeon.Withdraw}
        </Button>
      )}
    </div>
  );
}
