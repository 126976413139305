import { Text, useBoolean } from "@chakra-ui/react";
import "../App.css";

import uniswap from "../img/icon/uniswap.png";
import snapshot from "../img/icon/snapshot.png";
import looksrare from "../img/icon/looksrare_origin.png";
import X2Y2 from "../img/icon/X2Y2_origin.png";

const icon = {
  uniswap: {
    icon: uniswap,
    messages: "SWAP DCF",
    url: "https://app.uniswap.org/#/swap?outputCurrency=0xC74Ab3FF349cE64a349854BcD5dc2D542371350F",
  },
  snapshot: {
    icon: snapshot,
    messages: "Vote",
    url: "https://snapshot.org/#/minerpunk.eth/",
  },
  X2Y2: {
    icon: X2Y2,
    messages: "X2Y2",
    url: "https://x2y2.io/collection/minerpunk-eth/items",
  },
  looksrare: {
    icon: looksrare,
    messages: "looksrare",
    url: "https://looksrare.org/collections/0x90544049d50c012caF6F5F1C10344b7A9c05A064",
  },
};

export function LinkPort(i) {
  const [flag, setFlag] = useBoolean();

  return (
    <div
      onMouseEnter={setFlag.on}
      onMouseLeave={setFlag.off}
      className="row_center"
    >
      {flag ? (
        <a href={icon[i].url} target="_blank">
          <div
            className="linkbar"
            style={{ backgroundColor: "#6b6b6b", transition: " 200ms" }}
          >
            <img
              alt="dcoffer"
              src={icon[i].icon}
              width="15px"
              style={{
                transition: " 200ms",
                margin: "5px",
              }}
            />
            <Text color="white" fontSize="md" style={{ margin: "0px 15px" }}>
              {icon[i].messages}
            </Text>
          </div>
        </a>
      ) : (
        <a href={icon[i].url} target="_blank">
          <div className="linkbar" style={{ transition: " 200ms" }}>
            <img
              alt="dcoffer"
              src={icon[i].icon}
              width="15px"
              style={{ margin: "5px" }}
            />
            <Text color="white" fontSize="md" style={{ margin: "0px 15px" }}>
              {icon[i].messages}
            </Text>
          </div>
        </a>
      )}
    </div>
  );
}
