import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import { Container, Row, Col } from "react-bootstrap";
import { getlangselect } from "../Components/lang/langselect";
import { Socialmedia } from "../Components/Socialmedia";
import { LinkPort } from "../Components/LinkPort";
import { IntroA, IntroB, IntroC, IntroD, IntroE } from "../Components/NFTintro";

import AOS from "aos";
import Navbar from "../Components/Navbar";
import Footer from "../Components/footer";
import MyNFT from "../img/myNFT.png";
import logo from "../img/logo.png";

import { ChakraProvider, Button, Heading, Select } from "@chakra-ui/react";

AOS.init();

function MyLang() {
  const [lang, setLang] = useState("en");
  const navigate = useNavigate();
  let { paramName } = useParams();

  const handleSelectChange = (event) => {
    const newParamName = event.target.value;
    setLang(newParamName);
    // 使用navigate进行导航，更新URL中的参数
    navigate(`/${newParamName}`);
  };

  const _langSelect = (
    <Container>
      <Select
        value={paramName}
        onChange={handleSelectChange}
        color="teal"
        size="xs"
      >
        <option value="en">English</option>
        <option value="zh-tw">繁體中文</option>
        <option value="zh-cn">简体中文</option>
        <option value="ja">日本語</option>
      </Select>
    </Container>
  );

  return [lang, _langSelect];
}

export function Home() {
  const [lang, _langSelect] = MyLang();
  const LangPackage = getlangselect(lang);

  const MintBtn = (w, h) => {
    return (
      <div>
        <Link to={`/miner/Mint/${lang}`}>
          <Button colorScheme="teal" style={{ width: w, height: h }}>
            Launch APP
          </Button>
        </Link>
      </div>
    );
  };

  const BrandBtn = (w, h) => {
    return (
      <div>
        <Link to={`/brandstory/${lang}`}>
          <Button
            colorScheme="teal"
            variant="outline"
            style={{ width: w, height: h }}
          >
            {LangPackage.home.brandstory}
          </Button>
        </Link>
      </div>
    );
  };

  const Intro = () => {
    return (
      <>
        <div style={{ backgroundColor: "black" }}>
          {IntroA(lang)}
          {IntroB(lang)}
          {IntroC(lang)}
          {IntroD(lang)}
          {IntroE(lang)}
        </div>
      </>
    );
  };

  return (
    <ChakraProvider>
      <div>
        <Navbar name={MintBtn("100px", "25px")} lang={_langSelect} />
        <body className="body_style ">
          {/* home */}

          <div className="section_style_04">
            <Container className="section_style_00">
              <Row className="justify-content-md-center">
                <Col className="row_center" md="6">
                  <img
                    alt="dcoffer"
                    src={MyNFT}
                    width="500"
                    className="d-inline-block align-center"
                  />
                </Col>
                <Col className="column_center" md="6">
                  <div className="column_center">
                    <img
                      alt="dcoffer"
                      src={logo}
                      width="75%"
                      className="d-inline-block align-center"
                      style={{ margin: "30px 0px 10px 0px" }}
                    />

                    <Container
                      className="row_center"
                      style={{ margin: "30px" }}
                    >
                      {Socialmedia("DC")}
                      {Socialmedia("IG")}
                      {Socialmedia("TG")}
                      {Socialmedia("TW")}
                      {Socialmedia("github")}
                    </Container>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        width: "280px",
                      }}
                    >
                      {LinkPort("X2Y2")}
                      {LinkPort("looksrare")}
                    </div>
                    <div style={{ marginTop: "30px" }}>{MintBtn("260px")}</div>
                    <div style={{ marginTop: "10px" }}>{BrandBtn("260px")}</div>
                  </div>
                </Col>
              </Row>
              <div className="column_center">
                <div className="mouse_scroll">
                  <div className="mouse">
                    <div className="wheel"></div>
                  </div>
                  <div>
                    <span className="m_scroll_arrows unu"></span>
                    <span className="m_scroll_arrows doi"></span>
                    <span className="m_scroll_arrows trei"></span>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          {/* NFT */}
          <section className="section_style_01 cosGradient02">
            <Container className="center">
              <Heading data-aos="fade-up" data-aos-duration="3000">
                {LangPackage.home.head1}
              </Heading>
            </Container>
          </section>

          {Intro()}
        </body>
        <Footer />
      </div>
    </ChakraProvider>
  );
}
