import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import { getlangselect } from "../Components/lang/langselect";
import minerpunkIntroA from "../img/minerpunkIntroA.png";
import minerpunkIntroB from "../img/PowerDemo.png";
import minerpunkIntroC from "../img/minerpunkIntroB1.gif";
import minerpunkIntroD from "../img/myDcoff.png";
import minerpunkIntroE from "../img/DAO.png";

import AOS from "aos";

import { Text } from "@chakra-ui/react";

AOS.init();

export function IntroA(t) {
  const LangPackage = getlangselect(t);

  return (
    <>
      <Text fontSize="md" color="white" as="b">
        <Container className="center" style={{ height: "100vh" }}>
          <Row>
            <Col md={6} className="center">
              <img alt={minerpunkIntroA} src={minerpunkIntroA} />
            </Col>
            <Col
              md={6}
              className="center"
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <div style={{ width: "350px" }}>{LangPackage.home.section1}</div>
            </Col>
          </Row>
        </Container>
      </Text>
    </>
  );
}

export function IntroB(t) {
  const LangPackage = getlangselect(t);

  return (
    <>
      <Text fontSize="md" color="white" as="b">
        <Container className="center" style={{ height: "100vh" }}>
          <Row>
            <Col
              md={6}
              className="center order-md-1"
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <div style={{ width: "350px", margin: "50px 0px " }}>
                {LangPackage.home.section2}
              </div>
            </Col>
            <Col
              md={6}
              className="center order-md-2"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <img
                className="cosCard"
                alt={minerpunkIntroB}
                src={minerpunkIntroB}
              />
            </Col>
          </Row>
        </Container>
      </Text>
    </>
  );
}

export function IntroC(t) {
  const LangPackage = getlangselect(t);

  return (
    <>
      <Text fontSize="md" color="white" as="b">
        <Container className="center" style={{ height: "100vh" }}>
          <Row>
            <Col md={6} className="center">
              <img alt={minerpunkIntroC} src={minerpunkIntroC} />
            </Col>
            <Col
              md={6}
              className="center"
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <div style={{ width: "350px" }}>{LangPackage.home.noStaking}</div>
            </Col>
          </Row>
        </Container>
      </Text>
    </>
  );
}

export function IntroD(t) {
  const LangPackage = getlangselect(t);

  return (
    <>
      <Text fontSize="50px" color="white" as="b">
        <Container className="center" style={{ height: "50vh" }}>
          {LangPackage.home.head3}
        </Container>
      </Text>
      <Text fontSize="md" color="white" as="b">
        <Container className="center" style={{ height: "100vh" }}>
          <Row>
            <Col
              md={6}
              className="center order-md-1"
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <div style={{ width: "350px", margin: "30px 0px " }}>
                {LangPackage.home.section3}
              </div>
            </Col>
            <Col
              md={6}
              className="center order-md-2"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <img
                className="DCFLight"
                alt={minerpunkIntroD}
                src={minerpunkIntroD}
              />
            </Col>
          </Row>
        </Container>
      </Text>
    </>
  );
}

export function IntroE(t) {
  const LangPackage = getlangselect(t);

  return (
    <>
      <Text fontSize="md" color="white" as="b">
        <Container className="center" style={{ height: "100vh" }}>
          <Row>
            <Col md={6} className="center">
              <img alt={minerpunkIntroE} src={minerpunkIntroE} />
            </Col>
            <Col
              md={6}
              className="center"
              data-aos="fade-up"
              data-aos-duration="6000"
            >
              <div style={{ width: "350px" }}>{LangPackage.home.section4}</div>
            </Col>
          </Row>
        </Container>
      </Text>
    </>
  );
}
