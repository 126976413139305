import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Components/Navbar";
import { ChakraProvider } from "@chakra-ui/react";
import nopage from "../img/pagenofound.png";

function Nopage() {
  return (
    <ChakraProvider>
      <div>
        <Navbar />
        <div
          className="row_center section_style_01 "
          style={{ minHeight: "100vh" }}
        >
          <img alt="404" src={nopage} width="300" />
        </div>
      </div>
    </ChakraProvider>
  );
}

export default Nopage;
